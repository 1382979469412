.hidethis {
  display: none;
}

.nowrap {
  white-space: nowrap;
}

.modal-link {
  cursor: pointer;
}

.max-vh-h100 {
  max-height: 100vh !important;
}

.max-vh-w100 {
  max-width: 100vw !important;
}

.margin-r3px {
  margin-right: 3px;
}

.margin-r4px {
  margin-right: 4px;
}

.margin-r5px {
  margin-right: 5px;
}
