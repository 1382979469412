
.product-tile-headline {
  background: $color-white;
  padding: 1.5rem 5px .3rem;
  position: relative;
  z-index: 1;

  h2 {
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.product-tile {
  position: relative;
  //border-bottom: 1px solid $color-white;

  .box {
    overflow: visible;
    background: $color-white;
    text-align: left;
    box-shadow: 0 0 15px 15px rgba(0, 0, 0, 0.05);
    margin-top: 3rem;
    transition: transform 0.3s ease-in-out;

    &.first {
      margin-top: -8rem;
    }
  }

  @media (hover: hover) {
    &:not(.product-tile-rechner) {
      .box {
        &:hover {
          transform: scale(1.02);
        }
      }
    }
  }

  #calculator2021 {
    margin-top: 0 !important;

    @include breakpoint(medium down) {
      #nfx-calculator-app {
        padding-top: 2rem;
      }
    }
  }


  .description {
    font-size: rem-calc(21px);
    font-weight: 300;
    color: $color-white;
    padding: .6rem 1rem .5rem;
    background: $color-primary;
    display: inline-block;
    float: right;
    margin-top: 3rem;
  }

  .product-image {
    padding-right: 0;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0;
    opacity: 1;
    min-height: 520px;
    transition: opacity 2s ease-out;
  }

  .product-text {
    background: $color-white;
    padding: 4rem;
    padding-right: 6rem;
    position: relative;
  }

  h2 {
    font-size: rem-calc(20px);
    font-weight: $font-weight-semibold;
    color: $color-darkblue;
  }

  p {
    @include scale-font(16, 21);
    color: $color-darkblue;

    &.claim {
      font-size: rem-calc(40px);
      font-weight: 300;
      margin-top: 3rem;
      line-height: 1.2;
      padding-bottom: 1rem;
    }
  }

  .tile-arrow {
    position: absolute;
    right: 3rem;
    bottom: 3rem;
  }

  &.product-tile-compact {
    padding: 2rem;
    background: $color-white;
    text-align: left;
    box-shadow: 0 0 15px 15px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease-in-out;
    margin-bottom: 3rem;
    margin-top: 3rem;
    height: calc(100% - 6rem);
    display: flex;
    flex-direction: column;

    hr {
      width: 100%;
    }

    .list {
      flex-grow: 1;
    }

    .btn {
      float: none !important;
      position: relative;
      margin: 2rem auto 1rem;
    }
  }

  &.first {
    z-index: 1;
  }

  &.product-tile-rechner {
    z-index: 0;

    .box {
      margin-top: 0;
    }
  }


  &.product-tile-pk {
    .product-image {
      background-image: url('../images/jumbotron-pk-visual-digital.jpg');
    }
  }

  &.product-tile-sk {
    .product-image {
      background-image: url('../images/jumbotron-sk-visual.jpg');
      background-position: right 0;
    }
  }

  &.product-tile-sommerkredit {
    .product-image {
      background-image: url('../images/jumbotron-sommerkredit-visual.jpg');
      background-position: left 0;
    }
  }

  &.product-tile-sommerkredit2021 {
    .product-image {
      //background-image: url('../images/producttile-sommerkredit2021-visual-split.jpg');
      background-image: url('../images/producttile-ratenkredit2021-visual-split.jpg');
      background-position: center top;
    }
  }

  &.product-tile-winterkredit {
    .product-image {
      background-image: url('../images/jumbotron-winterkredit-visual.jpg');
      background-position: left 0;
    }
  }

  &.product-tile-hk {
    .product-image {
      background-image: url('../images/product-image-hk.png');
    }
  }

  &.product-tile-uk {
    .product-image {
      background-image: url('../images/product-image-uk.jpg');
    }
  }

  &.product-tile-wk {
    .product-image {
      background-image: url('../images/product-image-wk.png');
    }
  }

  .product-overlay {
    display: none;
    //background: rgba(0, 157, 223, 0.65);

    background: -moz-linear-gradient(top, rgba(0, 157, 224, 0.75) 0%, rgba(0, 157, 224, 0.75) 1%, rgba(0, 157, 224, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 157, 224, 0.75) 0%, rgba(0, 157, 224, 0.75) 1%, rgba(0, 157, 224, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 157, 224, 0.75) 0%, rgba(0, 157, 224, 0.75) 1%, rgba(0, 157, 224, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d9009de0', endColorstr='#009de0', GradientType=0); /* IE6-9 */

    //height: 130px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -25px;
    padding: 1.5rem 1rem 0;
    //border-bottom: 1px solid red;
  }

  .mobile-content {
    padding: 2rem 1.1rem;
    background: $color-primary;

    p {
      color: $color-white;
    }
  }

  @include breakpoint(medium down) {

    p {

      &.claim {
        margin-top: 2rem;
      }
    }

    .product-text {
      padding: 4rem 6rem 2rem 3rem;
    }

    &.product-tile-pk {
      .product-image {
        background-position: 75% 0;
      }
    }

  }

  @include breakpoint(small only) {

    border: 0;

    h2 {
      font-weight: bold;
      color: $color-white;
      margin-bottom: 5px;
    }

    p {
      color: $color-white;
    }

    .product-overlay {
      display: block;
    }

    .box {
      margin: 0;

      &.first {
        margin-top: 0;
      }
    }

    .product-image {
      min-height: 330px;
    }

    .description {
      bottom: 0;
      position: absolute;
      left: 0;
    }

    .product-text {
      padding: 2rem 1rem 4rem;
    }

    p.claim {
      font-size: rem-calc(30px);
      margin-top: .6rem;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .tile-arrow {
      right: 1.4rem;
      bottom: 1.1rem;
      width: 50px;
    }

  }


  @media screen and (max-width: 320px) {
    .tile-arrow {
      right: .85rem;
      bottom: .7rem;
    }
  }

}
