.content-group-357 {
  #calculator,
  #mobile-calculator {
    &.is-max-amount {
      .badge {
        opacity: 0;
      }

      .badge.is-large {
        transform: translateY(-100%);
        opacity: 1;
        top: 0 !important;

        i {
          font-size: 30px;
          vertical-align: sub;
          margin-right: 5px;
        }

        @include breakpoint(small only) {
          transform: none;
          top: -10px !important;
          z-index: 11;
        }
      }
    }
  }
}
