section.calculator {
  position: relative;
  z-index: 2;
  margin-top: -10vh;
  overflow: visible;

  @include breakpoint(small down) {
    margin-top: 0;
  }
}

//Slider
.slider-wrapper {
  width: 100%;
  padding-right: 0;
  position: relative;
  //height: 27px;
  padding-top: 0;
  display: block;
  padding-top: 10px;

  .ui-widget-header {
    border: 0;
    background: $color-white;
    height: 8px;
    border-radius: 50px;
  }

  .ui-widget-content {
    background: $color-darkblue-text;
  }

  .slider.ui-slider-horizontal {
    width: 100%;
    border-radius: 25px;
    position: relative;
    height: 8px;
    //background: #0585ba !important;
    box-shadow: none;
    border: 0;
    margin-right: 10px;

    .ui-slider-handle {
      position: absolute;
      z-index: 2;
      width: 20px;
      height: 20px;
      cursor: pointer !important;
      background: $color-secondary;
      border-radius: 50%;
      font-weight: bold;
      outline: none;
      margin-left: -10px;
      margin-top: -6px;
    }
  }
}

.slider-display {
  position: absolute;
  right: 0;
  top: 15px;
  margin-top: -2px;
  background: #fff;
  border-radius: 7px;
  text-align: center;
  font-size: rem-calc(22px);
  font-weight: $font-weight-semibold;
  width: 140px;
  height: 46px;
  line-height: 49px;
  color: $color-darkblue;
  display: block;
  //padding: 1rem 2rem .5rem;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 32px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-right: 8px solid #fff;

    display: none;
  }

  @media screen and (max-width: 320px) {
    font-size: 1.2rem;
    line-height: 45px;
    width: 115px;
    height: 40px;
  }


}

//Rechner
#calculator {
  &.jumbo-calc-container {
    width: 100%;
    margin-left: 0 !important;
    min-height: 200px;
  }

  > .row {
    position: relative;
    z-index: 9;
  }
}

.rap-hide {
  display: none;
}

.calc-container {
  padding-bottom: 0;
  background: $color-primary;
  background: radial-gradient(#5ac1ed, #009de0);
  position: relative;
  color: $white;
  font-weight: normal;

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $white;
  }

  h3 {
    font-size: 2rem;
    padding: 45px 0 10px 25px;
  }

  .fa-spin {
    font-size: 42px;
    position: absolute;
    left: calc(50% - 21px);
    top: calc(50% - 21px);
    transition: opacity 0.5s ease-out, display 0.1s ease-out 1s;
  }

  &.calc-container-ready {
    .fa-spin {
      opacity: 0;
      display: none;
    }

    > .row {
      opacity: 1;
    }
  }

  &.calc-container-calculating {
    .skg-rechner-monatsrate {
      filter: blur(3px);
    }
  }

  > .row {
    transition: opacity 0.3s ease-out;
    opacity: 0;
    height: 100%;
    align-content: center;
  }

  h2 {
    color: $color-white;

    @include breakpoint(small down) {
      text-align: center;
    }
  }

  .jumbo-rechner-inner-wrapper {
    padding: 10px 0 10px 30px;
  }

  p.skg-rechner-label {
    font-size: 1.125rem;
    font-weight: $font-weight-semibold;
    margin: 20px 0 5px;

    &:first-child {
      margin-top: 0;
    }
  }

  .skg-rechner-small-info {
    font-size: 12px;
    padding-bottom: 30px;

    p {
      margin-top: 10px;
      margin-bottom: 10px;

      strong {
        color: #fff;
      }
    }
  }

  p.skg-rechner-label-zusatz {
    //font-size: 1.125rem;
    line-height: 1;
    //font-weight: 400;
  }

  .skg-rechner-monatsrate-container {
    padding: 20px 10px;
    background: $color-darkerblue;
  }

  .skg-rechner-monatsrate {
    transition: all 0.4s ease-out;
    font-size: 2.5rem !important;
    font-weight: 400;
    line-height: 1.1;
    //margin: 20px 0 30px 0;
    white-space: nowrap;
    float: right;
  }

  .calc-table {
    padding: 20px;
    background: $color-darkerblue;
    background: rgba(4, 148, 210, 0.6);

    tbody {
      background: transparent;
    }

    &.rap2 {
      padding: 20px !important;
      margin-bottom: 1rem !important;

      .monatsrate-box td {
        padding-top: 4px !important;
      }
    }

    td {
      white-space: nowrap;
    }
  }

  .btn {
    width: 100%;
    margin: 20px 0 0;
  }

  .range-text {
    margin-top: -30px;
    padding-bottom: 50px;
    font-size: .9rem;
    font-weight: 700;

    .left {
      position: absolute;
      left: 0;
    }

    .right {
      position: absolute;
      right: 0;
    }
  }

  a.btn.cta {
    padding: 1rem 3rem .8rem;
    float: right;
  }

  .warningbox {
    overflow: hidden;
    opacity: 0;
    //position: absolute;
    height: 0;
    padding: 0 15px;
    background-color: rgba(255, 255, 255, 0.3);
    transition: opacity 0.8s ease-out, height 0.4s ease-out, margin 0.4s ease-out, padding 0.4s ease-out;

    &.active {
      padding: 15px;
      margin: 20px 0;
      //position: static;
      height: 5rem;
      opacity: 1;
    }
  }

  @include breakpoint(large down) {
    p.skg-rechner-label {
      font-size: 1rem;
    }

    .skg-rechner-monatsrate {
      font-size: 2rem !important;
    }
  }


  @include breakpoint(small down) {
    color: $color-white;
    padding: 20px;
    width: 100%;
    height: 100%;
    z-index: 10;

    .rechner-inner-wrapper {
      margin: 10px 0 0;
    }

    p.skg-rechner-label {
      font-size: .9rem;
    }

    p.skg-rechner-label-zusatz {
      font-size: 1.2rem;
    }

    .skg-rechner-small-info {
      //background: $color-darkerblue;
      padding-top: 10px;
      padding-bottom: 10px;

      table {
        margin-bottom: 0;
      }

      p {
        font-size: .9rem;
        margin-top: 0;
        margin-bottom: 0;
      }

      img.margin-t-5 {
        margin-top: -10px;
      }
    }

    .calc-table {
      background: $color-darkerblue;
      padding: 13px 10px 10px;
    }

    .skg-rechner-label-monatsrate {
      margin-top: 20px;
      margin-bottom: 0 !important;
    }

    .skg-rechner-monatsrate {
      font-size: 65px;
      text-align: right;
      margin: 0;
    }

    .btn {
      width: 100%;
      margin: 20px 0 0;
    }
  }


  @media screen and (max-width: 320px) {
    p.skg-rechner-label-zusatz {
      font-size: 1.1rem;
    }

    h2 {
      font-size: 1.7rem;
    }

  }

}

.button-box {
  margin-bottom: 0;
}

.toggle-box {
  margin: 10px auto;
  text-align: left;

  &.toggle-box-condition {
    text-align: center;
    padding: .5rem 0 0;
    margin: 0;
  }

  .morelink {
    font-size: .9rem;
    line-height: 1.5;
    color: $color-white;
    font-weight: 400;
    margin-bottom: 0;
    position: relative;
    min-height: 40px;
    display: inline-block;

    &.big {
      color: $color-primary;
      font-size: 1.25rem;
      padding: 3px 1.5em 8px;
      min-height: 0;
    }

    &:after {
      content: '';
      font-family: 'Linearicons-Free';
      display: block;
      position: absolute;
      margin: 0 auto;
      top: 0;
      right: 0;
      width: 1em;
      height: 1em;
      background-repeat: no-repeat;
      background-size: 15px 15px;
    }

    &.close {
      &:after {
        content: '';
      }
    }
  }
}

.jumbo-benefits {
  flex: 0 0 23%;
  max-width: 23%;
}

.calc-container {
  flex: 0 0 77%;
  max-width: 77%;

  small,
  .small {
    font-size: 85%;
  }

  &.is-fullwidth {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    padding: 0 1rem 2rem;

    @include breakpoint(large) {
      flex-direction: row;
      padding: 0 2rem;
    }
  }
}

.calc-left-box {
  flex: 0 0 54%;
  max-width: 54%;
  padding-left: 0;
}

.calc-right-box {
  padding-left: 3%;
  padding-right: 2.5%;
  flex: 0 0 45%;
  max-width: 45%;

  > .row {
    height: 100%;
    align-content: flex-end;
  }
}

.infotext-box {
  text-align: left;
  width: 100%;
  padding: 15px 0;

  p.infotext-desktop {
    font-size: rem-calc(14px);
    color: $color-grey-text;
  }
}

@include breakpoint(medium down) {
  .jumbo-benefits {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .calc-container {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .calc-left-box {
    flex: 0 0 53%;
    max-width: 53%;
  }

  .calc-right-box {
    flex: 0 0 43%;
    max-width: 45%;
    padding-right: 0;
  }
}

@include breakpoint(small down) {
  .calc-container {
    padding-top: 30px;
  }

  .toggle-box {
    margin: 10px auto 0;
    text-align: center;

    .morelink {
      font-size: .9rem;
      line-height: 1.5;
      color: $color-white;
      font-weight: 400;
      margin-bottom: 0;
      margin-left: -2rem;
      margin-top: 5px;
      position: relative;
      min-height: 40px;

      &:after {
        content: '';
        font-size: 1.2rem;
        margin: 3px auto;
        background-repeat: no-repeat;
        background-size: 15px 15px;
      }

      &.close {
        &:after {
          content: '';
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .toggle-box {
    .morelink {
      &:after {
        left: 60%;
      }
    }
  }
}

p.infotext-desktop {
  font-size: rem-calc(14px) !important;
  color: $color-grey-text !important;

  @include breakpoint(medium down) {
    font-size: rem-calc(12px) !important;
  }
}

@include breakpoint(small down) {
  .infotext-box {
    p.infotext-desktop {
      color: $color-white !important;
    }
  }
}

.privatkredit {
  .jumbotron {
    p.infotext-desktop {
      padding-top: 1rem;
      text-align: left;
    }
  }

  .condition-container {
    p.infotext-desktop {
      text-align: left;
      padding: 0.5rem 0;
    }
  }

}


#calculator2021 {
  position: relative;
  z-index: 5;

  .small {
    padding: 0.5rem 1rem 2rem;
    color: $color-white;
    opacity: 0.5;

    @include breakpoint(small down) {
      padding: 0;
    }
  }

  .calc-container {
    flex-wrap: wrap;
  }

  .container {
    overflow: visible;
  }
}

