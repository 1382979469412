#mobile-usps {
  display: block;
  position: relative;
  padding: 0 20px 0 30px;
  margin-top: 25px;
  color: $color-primary;

  .container {
    clear: both;
    display: block;
  }

  ul {
    list-style-type: none;

    li {
      font-size: 1.25rem;
      line-height: 1.2;
      position: relative;
      padding: 10px 0 5px 10px;

      &:before {
        content: '';
        font-family: 'fontawesome';
        display: block;
        position: absolute;
        top: 7px;
        left: -20px;
        width: 10px;
        height: 14px;
        background-repeat: no-repeat;
        background-size: 10px 14px;
      }
    }
  }


}