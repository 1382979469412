.cc-window {
  font-family: $body-font-family;
  font-size: rem-calc(15);
  border-radius: 0 !important;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);

  .cc-btn {
    &:hover,
    &:active,
    &:focus {
      background-color: $color-primary-dark !important;
    }

    &:last-child {
      min-width: 80px;
    }

  }
}
