#trees {
  opacity: 1 !important;
  transform: none !important;

  .background-image {
    object-position: center;
  }

  .thumbnail {
    display: block;
    float: right;
    margin: 0.5em;
    width: 130px;
    height: 130px;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
    border: 10px solid rgba(255, 255, 255, 0.3);
    position: relative;
    //position: absolute;
    //transform: translateY(-120%);

    > img {
      max-width: 100%;
    }

    > svg {
      position: absolute;
      transform: translate(-50%, 50%);
      width: 40%;
      bottom: 50%;
      left: 50%;
    }
  }

  #cta-section {
    padding-top: 2rem;
    padding-bottom: 0;
  }

  .jumbotron-content {
    max-height: 100%;
    padding-top: 5vw;

    &.is-top {
      transform: none !important;
      top: 5rem !important;

      #cta-section {
        padding-bottom: 5rem;
      }
    }

    h1,
    h2 {
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);

      strong {
        color: $color-tertiary;
      }
    }

    h1 {
      line-height: 1.1;
      font-size: 58px;
    }

    @include breakpoint(medium down) {
      h1 {
        font-size: 40px;
      }

      h2 {
        font-size: 25px;
      }
    }

    @include breakpoint(small down) {
      h1 {
        font-size: 34px;
      }

      h2 {
        font-size: 20px;
      }
    }

  }
}

.trees {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 0;

  img {
    transition: transform 1s cubic-bezier(0.175, 1.285, 0.32, 1.275);
    position: absolute;
    top: -5vh;
    left: -5vw;
    height: auto;
    width: auto;
    transform: scale(0);
    transform-origin: 30%;
    max-width: 200px;
    min-width: 50px;

    @for $i from 1 through 1000 {
      &:nth-child(#{$i}) {
        transition-delay: 0.5s + (0.005s * $i);
      }
    }

    &.tree0 {
      transform: scale(1);
      opacity: 0;
      transition: opacity 5s ease-in-out;
      transition-delay: 0s;
      width: 200px !important;
      z-index: 0 !important;
    }

    .grown & {
      opacity: 1;
      transform: scale(1);
    }
  }
}

#trees-progress {
  width: 100%;
  padding: 20px 50px;
  opacity: 1;
  margin-bottom: 2rem;

  @include breakpoint(medium) {
    padding: 20px 100px;
    margin-top: -1rem;
    margin-bottom: 4rem;
  }

  &.show-progress {
    .bar {
      transform: none !important;
    }

    .icon {
      opacity: 1;
    }
  }

  .icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-out;
    width: auto;
    height: 35px;

    @include breakpoint(medium) {
      height: 65px;
    }

    &:last-child {
      left: auto;
      right: 0;
    }
  }

  .progress {
    box-shadow: 0 0 10px rgba($color-black, 0.8);
    width: 100%;
    border-radius: 5px;
    height: 10px;
    background: #285520;
    position: relative;
    overflow: hidden;
    opacity: 0.85;

    .bar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 5px;
      background: $color-tertiary;
      transform: translateX(-100%);
      transition: all 1s ease-in-out;
      transition-delay: 2s;
      opacity: 1;
    }

  }
}

.trees-teaser {
  background: #051c13;
  height: 80vh;

  .background-image {
    opacity: 0.7;
    height: auto !important;

    @include breakpoint(medium) {
      min-height: 0 !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    text-align: left;
    margin-bottom: 0.5em !important;
  }

  svg {
    position: absolute;
    left: 60%;
    bottom: -10px;
    max-width: 80px;

    @include breakpoint(medium) {
      bottom: -20px;
      left: 10%;
      max-width: 210px;
    }

    @include breakpoint(large) {
      left: 25%;
      max-width: 250px;
    }
  }

  @include breakpoint(large) {
    min-height: 880px;
    max-height: 1100px;

    .jumbotron-content {
      .column:first-child {
        padding-right: 50px;
      }

      .column:last-child {
        padding-left: 50px;
        padding-top: 10px;
      }
    }
  }

  h5,
  p {
    line-height: 1.5;
    margin-bottom: 1em;
  }

  h5 {
    font-weight: $font-weight-semibold;
    color: $color-white;
  }

  #trees-progress {
    padding: 0;
    padding-bottom: 100px;
    margin-top: 30px;

    .progress {
      position: absolute;
      top: 60px;
    }

    .step {
      padding: 0;
      text-align: center;

      &.current {
        .thumbnail {
          width: 100px;
          height: 100px;
          margin-top: -50px;
          border-width: 6px;
          transition: all 0.1s ease-out;
          transform: scale(1.01);

          &:hover {
            transform: scale(1.05);
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
          }
        }
      }

      &.last {
        .thumbnail {
          opacity: 0;
        }
      }
    }

    > .icon {
      top: 50px;
    }

    .text {
      position: relative;
      top: 50px;
      font-size: 13px;
      text-align: center;
      padding-right: 0.5em;
      padding-left: 0.5em;
      hyphens: auto;
      -webkit-hyphens: auto;

      strong {
        display: block;
      }

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: -5px;
        transform: translateY(-100%);
        border-left: 1px dotted #fff;
        height: 20px;
        width: 1px;
      }
    }

    .thumbnail {
      width: 50px;
      height: 50px;
      border-width: 4px;
      margin: 0;
      float: none;
      display: inline-block;
      background: rgba(#375237, 0.9);
    }
  }

}
