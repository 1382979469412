.callout {
  border: 0;
  margin: 0;

  &.primary {
    background: $color-primary-light;
  }

  h4 {
    font-size: rem-calc(19);
    text-align: left;

    @include breakpoint(medium only) {
      font-size: rem-calc(16);
    }

    @include breakpoint(small only) {
      font-size: rem-calc(14);
    }
  }

  img {
    height: 4.7em;
    float: left;
    margin-right: 20px;

    @include breakpoint(small only) {
      height: 4.3em;
    }
  }

  &.one-line {

    .column {
      @include breakpoint(medium down) {
        padding-bottom: 10px;
      }

      @include breakpoint(small only) {
        text-align: center;
        height: auto !important;
        padding-top: 1rem;
      }
    }

    .last & {
      .column {
        border-left: 1px solid rgba($color-primary, 0.2);

        @include breakpoint(small only) {
          border-left: 0;
          border-top: 1px solid rgba($color-primary, 0.2);
          padding-top: 2rem;
        }
      }
    }

    img {
      height: 40px;
      margin-right: 15px;

      @include breakpoint(small only) {
        height: 36px;
      }
    }

    .btn {
      font-size: rem-calc(12);
      text-align: center;
      position: relative;
      top: -2px;
      left: 10px;
      padding: 6px 20px 5px;

      @include breakpoint(medium down) {
        top: 10px;
        left: 0;
      }

      @include breakpoint(small down) {
        float: right;
      }
    }

  }

  p {
    margin: 0;
  }

  .column:last-child {
    text-align: right;

    @include breakpoint(small only) {
      text-align: center;
      height: 60px;
    }
  }

  .btn {
    white-space: nowrap;
    position: relative;
    top: 14px;

    @include breakpoint(small only) {
      top: 20px;
    }
  }

  &.callout-condition {
    padding: 0;
  }
}

