[data-scroll] {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.6s ease-out;
}

.animated {
  &.first {
    transition-delay: 0s;
  }

  &.second {
    transition-delay: 0.1s;
  }

  &.third {
    transition-delay: 0.2s;
  }

  &.fourth {
    transition-delay: 0.3s;
  }

  &.fifth {
    transition-delay: 0.4s;
  }

  &.sixth {
    transition-delay: 0.5s;
  }
}

.animate-fade-in-up {
  opacity: 1;
  transform: translateY(0);
}

.tada,
.swing {
  opacity: 1;
}


@include breakpoint(small) {
  $init-delay: 0s;
  $slow-down: 1.2;

  .animate-svg {
    svg {
      overflow: visible;
    }

    g {
      transition: all (0.5s * $slow-down) cubic-bezier(1, 0.24, 0.19, 1.4);
      transform: translateY(-200px);
      opacity: 0;
      filter: blur(3px);

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          transition-delay: $init-delay + (0.1s * $i * $slow-down);
        }

        &#ani#{$i} {
          transition-delay: $init-delay + (0.1s * $i * $slow-down);
        }
      }
    }

    &.fall-down-noblur {
      g {
        transition: all (0.5s * $slow-down) cubic-bezier(1, 0.24, 0.19, 1.1);
        transform: translateY(-200px);
        opacity: 0;
        filter: none;

        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            transition-delay: $init-delay + (0.1s * $i * $slow-down);
          }

          &#ani#{$i} {
            transition-delay: $init-delay + (0.1s * $i * $slow-down);
          }
        }
      }
    }

    &.scale-from-center {
      g {
        transition: all (0.8s * $slow-down) cubic-bezier(1, 0.24, 0.19, 1.2);
        transform: scale(0.2);
        transform-origin: center;
        filter: none;

        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            transition-delay: $init-delay + (0.06s * $i * $slow-down);
          }

          &#ani#{$i} {
            transition-delay: $init-delay + (0.06s * $i * $slow-down);
          }
        }
      }
    }

    .animate-now {
      g {
        transform: none;
        opacity: 1;
        filter: none;
      }
    }

  }

  .animate-scroll {
    transition: all 0.3s cubic-bezier(1, 0.24, 0.19, 1.4);
    transform: translateY(-100px);
    opacity: 0;
    //filter: blur(10px);

    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        transition-delay: $init-delay + (0.06s * $i);
      }

      &.animate-scroll-#{$i} {
        transition-delay: 0.06s * $i;
      }
    }

    &.noblur {
      filter: none;
    }

    &.animate-now {
      transform: translateY(0);
      opacity: initial;
      filter: none !important;
    }

  }

}

.animate-scroll-zoom {
  transition: all 0.3s cubic-bezier(1, 0.24, 0.19, 1.4);
  transform: scale(0.5);
  opacity: 0;
  //filter: blur(10px);

  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      transition-delay: 0.06s * $i;
    }
  }

  &.animate-now {
    transform: scale(1);
    opacity: 1;
    filter: blur(0);
  }

}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(40px);
  }

  100% {
    transform: translateY(0);
  }
}

.animation-float {
  animation-name: float;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}
