#steps {
  background: $color-white;
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 0;

  h2 {
    color: $color-darkblue;
    padding-bottom: 0;
  }

  h3 {
    font-size: rem-calc(26px);
    font-weight: $font-weight-semibold;
    color: $color-darkblue;
    padding: 3rem 0;
  }

  p {
    @include scale-font(16, 21);
    font-weight: 300;
    color: $color-darkblue;
    padding-bottom: 3rem;

    &.subline {
      padding-top: 0;
    }
  }

  svg {
    [fill='#ffffff'],
    [fill='#FFFFFF'] {
      fill: $color-primary-light;
      opacity: 1;
    }

    [fill='#00384F'],
    [fill='##00384f'] {
      fill: $color-primary;
      opacity: 1;
    }
  }

  #steps-tabs {
    text-align: center;
  }

  .tabs-title {
    float: none;
    display: inline-block;

    a {
      font-size: rem-calc(26px);
      font-weight: $font-weight-semibold;
      color: $color-grey-inactive;
      margin-top: 20px;
      padding-bottom: 20px;
      padding-left: 65px;
      padding-right: 0;
      margin-left: 60px;
      margin-right: 60px;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -16px;
        left: 0;
        width: 55px;
        height: 55px;
        background-image: url('../images/lp17-icon-rocket.png');
        background-repeat: no-repeat;
        background-size: 55px 100px;
        background-position-y: -24px;
      }

      &:focus {
        background: none;
      }
    }

    &:first-child {
      a:before {
        background-image: url('../images/icon-steps-tab-1-grey.svg');
        background-position-y: -24px;
      }
    }

    &:last-child {
      a:before {
        background-image: url('../images/icon-steps-tab-2-grey.svg');
      }
    }

    &.is-active {
      a {
        background: none;
        border-bottom: 2px solid $color-primary;
      }

      &:first-child {
        a:before {
          background-image: url('../images/icon-steps-tab-1.svg');
        }
      }

      &:last-child {
        a:before {
          background-image: url('../images/icon-steps-tab-2.svg');
        }
      }
    }

    &:hover {
      a {
        background: none;
      }
    }

    &.is-active {
      a {
        color: $color-primary;
      }
    }
  }

  .tabs {
    border: 0;
    background: none;
  }

  .tabs-content {
    border: 0;
    background: none;
    padding-top: 40px;
    padding-bottom: 20px;

    &.no-padding-top {
      padding-top: 0;
    }

  }

  .step {
    vertical-align: text-top;

    @media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) and (min-width: 39.9375em) {
      max-width: 33%;
    }

    img:not(.expanded),
    svg:not(.expanded) {
      height: 160px;
      max-width: 160px;
    }

    &.step-padding {
      @include breakpoint(large) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  .step-number {
    font-size: 1.25rem;
    background: $color-white;
    color: $color-primary;
    border: 2px solid $color-primary;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    padding: 4px;
    font-weight: 700;
    margin: 20px auto;
    display: inline-block;
    line-height: 1.25;
  }

  .step-support-image {
    padding-top: 2rem;
  }

  //1024
  @include breakpoint(large down) {

    .tabs-panel {
      padding: 0;
    }
  }

  @include breakpoint(medium down) {
    .step-number {
      font-size: 1.25rem;
    }
  }

  @include breakpoint(small down) {
    padding-top: 40px;

    .container {
      padding: 0 10px;
    }

    p {
      font-size: 1rem;
    }

    .tabs-title {
      float: left;
      width: 50%;

      a {
        white-space: nowrap;
        font-size: 1.1rem;
        margin: 0 20px;
        padding-left: 40px;
        padding-bottom: 10px;

        &:before {
          top: -12px;
          left: -.5rem;
          width: 44px;
          height: 44px;
          background-size: 44px 88px;
        }
      }

      &.is-active {
        a {
          &:before {
            background-position-y: -24px;
          }
        }
      }
    }

    .step {
      margin-bottom: 20px;

      p {
        //text-align: left;
        text-align: center;
      }

      img,
      svg {
        width: 80px;
        height: auto !important;
        max-width: 100% !important;
      }

    }

    .step-number {
      text-align: center;
      max-width: 35px;
      float: left;
      margin: 0 10px 15px 0;
    }

    .cta-box {
      margin-top: 0;
      margin-bottom: 3.5rem;
    }
  }

}




//*********************************
//   ***** MULTIPRODUCT *****
//*********************************

#steps {
  background: $color-white;
  margin-top: 2rem;
  padding-bottom: 0;

  h2 {
    color: $color-darkblue;
    padding-bottom: 0;
  }

  h3 {
    font-size: rem-calc(26px);
    font-weight: $font-weight-semibold;
    color: $color-darkblue;
    padding: 3rem 0;
  }

  p {
    @include scale-font(16, 21);
    font-weight: 300;
    color: $color-darkblue;
    padding-bottom: 3rem;

    &.subline {
      padding-top: 0;
    }

    &.text-large {
      font-size: rem-calc(24px);
      color: $color-black;
      font-weight: 600;
      padding-top: 3rem;
    }
  }


  //1024
  @include breakpoint(large down) {

    margin-top: 0;

    h3 {
      padding: 1.5rem 0;
    }
  }

  @include breakpoint(medium down) {

    padding-top: 3rem;

    p {
      padding-bottom: 1rem;
    }

  }

  @include breakpoint(small only) {

    padding-top: 1rem;

    .step {
      margin-bottom: 0;
    }

    h3 {
      padding: .5rem 0;
      margin-bottom: 0;
    }

    p {
      padding: 0 0 1rem;
      text-align: center;
    }
  }

}

.multiproduct {
  #steps {
    margin-top: 1.5rem;

    @include breakpoint(small only) {
      padding-bottom: 1rem;
    }
  }
}

.privatkredit,
.lp-hochzeit,
.umzugskredit {
  #steps {
    padding-top: 3rem;

    p:not(.text-large) {
      line-height: 1.8;
      padding-top: 2rem;
    }

    .step {
      img:not(.expanded),
      svg:not(.expanded) {
        width: 140px;
      }
    }

    @include breakpoint(small only) {

      padding-top: 3rem;

      p:not(.text-large) {
        line-height: 1.3;
        padding-top: 0;
      }

      .step {
        img,
        svg {
          width: 110px !important;
        }
      }
    }
  }
}
