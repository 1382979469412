.reveal {
  -webkit-overflow-scrolling: touch;
  border: 0;
  color: $color-white;
  padding: $reveal-padding;
  outline: none;
  max-height: 90vh;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);

  h2,
  h3 {
    color: $color-white;
  }

  .hl-3 {
    font-size: 2rem;
    line-height: 1.2;
    padding: 20px 0;
  }

  .close-button {
    font-size: 30px;
    color: $color-primary;
    //width: 25px;
    //height: 25px;
    //background: $color-darkerblue;
    border-radius: 100%;
    position: absolute;
    right: 25px;
    top: 25px;
    padding: 0 0 2px;
    font-weight: normal;
    cursor: pointer;
    pointer-events: auto;
  }

  //Tick LIST
  ul.list {
    list-style-type: none;

    li {
      font-size: 1.25rem;
      line-height: 1.2;
      position: relative;
      padding: 10px 0 5px 10px;

      &:before {
        content: '';
        font-family: 'fontawesome';
        display: block;
        position: absolute;
        top: 7px;
        left: -20px;
        width: 10px;
        height: 14px;
        background-repeat: no-repeat;
        background-size: 10px 14px;
      }
    }
  }

  @include breakpoint(small down) {
    padding: 20px;

    .hl-3 {
      font-size: 1.5rem;
      padding: 20px 0 15px;
    }

    ul {
      list-style-type: none;

      li {
        font-size: 1rem;
      }
    }
  }

}


#modal-faq {
  background: $color-white;

  h2 {
    color: $color-primary;
  }

  ul {
    li {
      padding-bottom: 0.5rem;

      li {
        padding-bottom: 0.8rem;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  .highlight {
    color: $color-primary;
    font-size: 1.5rem;
  }

  @include breakpoint(small down) {

    p {
      line-height: 1.4;
      font-size: .9rem;
    }

    ul {
      li {
        font-size: rem-calc(14px);

        ul {
          li {
            font-size: rem-calc(14px);
            line-height: 1.4;
          }
        }
      }
    }

    .highlight {
      font-size: 1.1rem;
      line-height: 1.8;
    }
  }
}


#modal-survey {
  width: 660px;
  background: $white;

  h2 {
    color: $color-primary;
    padding-bottom: 0;
  }

  p {
    color: $color-darkblue;
  }

  @include breakpoint(medium) {
    iframe {
      height: 960px;
    }
  }

  @include breakpoint(small only) {
    width: 100%;
    top: 0 !important;
    left: 0 !important;

    iframe {
      transform: scale(0.9);
      transform-origin: 0 0;
    }
  }
}

.sticky-badge {
  pointer-events: auto;
  cursor: pointer;
  border-bottom: 0;
  position: fixed;
  top: 56vh;
  padding: 1rem;
  padding-bottom: .8rem;
  width: 100px;
  transform: translateX(0);
  background-color: #fff;
  text-align: center;
  right: -110px;
  transform: scale(1);
  box-shadow: 0 0 20px rgba(10, 10, 10, 0.3);
  transition: transform 0.4s ease-in-out, right .4s;
  z-index: 999;

  &.close {
    transform: translateX(100px);
  }

  &.in {
    right: 0;
  }

  img {
    &.jump {
      //-webkit-animation: jump 1.2s ease 0s infinite normal ;
      animation: jump 1.2s ease 0s normal;
    }
  }

  p {
    font-size: 16px;
    font-weight: $font-weight-semibold;
    padding-top: .5rem;
    padding-bottom: 2px;
    line-height: 1.1;
    margin-bottom: 0;

    &.small {
      font-size: 11px;
      padding-top: 4px;
    }
  }

  &:hover {
    background: $color-primary;

    p {
      color: $color-white;
    }
  }

  @include breakpoint(large down) {
    width: 80px;
    padding: .8rem;
    padding-bottom: .5rem;

    p {
      font-size: 13px;

      &.small {
        display: none;
      }
    }


  }

}

#modal-survey {
  transition: all 0.6s cubic-bezier(1, 0.24, 0.19, 1.2);
  min-width: 300px;

  h2 {
    font-size: rem-calc(28);

    @include breakpoint(medium down) {
      font-size: rem-calc(22);
    }

    @include breakpoint(small down) {
      font-size: rem-calc(26);
    }
  }

  .expand {
    transition: all 0.4s ease-out;
    right: 40px;
    display: none;
  }
}

.modal-survey-open:not(.modal-survey-auto-open) {
  .modal-survey-overlay {
    display: block !important;
  }
}

.modal-survey-auto-open {
  overflow: auto !important;

  #modal-survey {
    background: rgba(255, 255, 255, 0.95);
    position: fixed;
    //right: 0 !important;
    top: 55vh !important;
    left: 100vw !important;
    height: 45vh !important;
    //bottom: 0 !important;
    width: 40vw !important;
    max-width: 575px;
    min-width: 280px;
    transform: translateX(-100%);

    .expand {
      display: inline-block;
    }

    @include breakpoint(medium up) {
      min-width: 420px;
    }

    @include breakpoint(large up) {
      min-width: 460px;
    }
  }
}
