body {
  font-family: 'Hind-web', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  color: $color-darkblue;
}


h1 {
  color: $color-text;
  font-weight: 300;
}

h2 {
  font-size: 3.75rem;
  color: $color-text;
  font-weight: 300;
}

h3 {
  font-size: rem-calc(36px);
  color: $color-text;
  font-weight: 300;
}

h4 {
  color: $color-text;
  font-weight: 300;
}

h5 {
  font-size: rem-calc(20px);
  color: $color-text;
  font-weight: $font-weight-semibold;
}

h5 + h2 {
  padding-top: 0 !important;
}

h1,
h2,
h3 {
  span {
    display: inline-block;
    padding-top: 0.3em;
    padding-bottom: 1.5em;
    height: 1.8em;
    margin-bottom: -1.5em;
    background: url('../images/icon-rate-bg-yellow.svg') center no-repeat;
    background-size: contain;
  }
}

a {
  color: $color-primary;
}

strong {
  font-weight: 700;
}

.font-handwritten {
  font-family: 'handwritten';
}


@include breakpoint(large down) {
  h1 {
    font-size: 3.25rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2rem;
  }
}


@include breakpoint(medium down) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.75rem;
  }
}

@include breakpoint(small down) {
  body {
    font-size: 15px;

    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 320px) {
  h3 {
    font-size: rem-calc(21px);
  }
}

.text-weight-regular {
  font-weight: 300 !important;
}
