.scroll-up {
  position: fixed;
  display: block;
  height: 50px;
  width: 50px;
  content: '';
  bottom: 25px;
  right: 0;
  background-image: url('../images/lp17-scrollup.png');
  background-size: 50px 50px;
  opacity: 0;
  z-index: 999;
  @media (max-width: 480px) {
    bottom: 5px;
  }
}
