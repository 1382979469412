.page {
  #nfx-calculator-app {
    --color-primary: #{$color-primary};
    --color-secondary: #{$color-secondary};
    --color-text: #{$color-white};
    --color-text-dark: #{$color-text};
    --slider-thumb-background: #{$color-secondary};

    background: transparent;
    padding: 2rem 0;

    @include breakpoint(medium) {
      padding: 3rem 1rem 0;
    }

    .calculator-result {
      display: none !important;
    }

    .section-calculator.is-horizontal {
      padding-bottom: 1rem;
      border-bottom: 1px dotted rgba($color-white, 0.6);
    }

  }
}
