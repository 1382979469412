.accordion {
  margin-bottom: 15px;
  background: none;
  width: 100%;

  .accordion-item {
    @include scale-font(16, 18);
    font-weight: 300;
    color: $color-text;
    cursor: pointer;
    border-bottom: 1px solid $color-line;
    padding: 13px 0 11px 18px;
    margin: 0;
    position: relative;

    .accordion-title {
      @include scale-font(16, 20);
      color: $color-text;
      border: 0;
      background: none;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 2px;

      &:before {
        color: $color-primary;
        font-size: 1.4rem;
        font-weight: 700;
        position: absolute;
        top: 50%;
        left: -20px;
        margin-top: -11px;
        height: 20px;
      }
    }

    &.is-active {
      .accordion-title {
        font-weight: $font-weight-semibold;
      }
    }

    &.ui-state-active {

      &:before {
        content: '–';
      }
    }

    .accordion-content {
      border: 0;
      padding-top: 0;
      padding-bottom: 2px;
      padding-left: 0;
      margin-left: 2px;
      margin-top: 8px;

      ul,
      ol {
        margin-bottom: 1em;
      }
    }
  }
}

.reveal {
  .accordion {
    .accordion-item {
      .accordion-content {
        margin-left: 1px;
      }
    }
  }
}
