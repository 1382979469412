#mobile-benefits {

  h2 {
    padding: 20px 0;
  }

  .special-box-container {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    flex: 0 0 37%;
    padding: 0;
    min-width: 100%;

    a {
      cursor: pointer;
    }

    .benefits-list {
      max-height: 0;
      opacity: 0;
      transition: opacity 0.25s linear, max-height .25s;
    }

    .special-box-inside {
      padding: 0;
      background: $color-yellow-bg;
      max-height: 0;
      opacity: 0;
      transition: opacity 0.25s linear, padding .25s, max-height .25s;

      .infotext {
        font-size: .85rem;
        line-height: 1.5;
        color: $color-darkblue-text;
        font-weight: 400;
        margin-bottom: 0;
        position: relative;
        display: inline-block;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -48px;
          left: -15px;
          width: 40px;
          height: 40px;
          background-image: url('../images/lp17-icon-info.png');
          background-repeat: no-repeat;
          background-size: 40px 40px;
        }
      }
    }

    #hide-mb-details {
      display: none;
    }

    #show-mb-details {
      display: block;
    }

    &.open {
      height: auto;

      .benefits-list {
        max-height: 300px;
        opacity: 1;
      }

      .special-box-inside {
        padding: 20px 20px 20px 25px;
        max-height: 300px;
        opacity: 1;
      }

      #hide-mb-details {
        display: block;
      }

      #show-mb-details {
        display: none;
      }
    }
  }

  .special-box {
    padding: 20px;
    background: $color-secondary url('../images/lp17-mobile-pyramids.png') 46% 100% no-repeat;
    //background-position-x: +100px;
    position: relative;

    &.special-box-flugs {
      background-image: url('../images/lp17-mobile-trees.png');
      background-position-x: right;
    }

    &.hochzeit-incentive {
      text-align: center;
      background-image: none;
    }

    .incentive {
      position: relative;
      height: 200px;
      width: 100%;

      img {
        position: absolute;
        max-height: 85%;
        top: 42%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .icon {
        right: 0;
      }

      .gutschein {
        left: 0;
      }
    }

    &.hochzeit {
      background-image: url('../images/bg-special-box-hochzeit.png');
      background-position: 81% 100%;
    }

    .btn-primary {
      background-color: $color-primary !important;
      color: $color-white !important;
      display: inline-block;
      margin-bottom: 20px;

      &:hover {
        background-color: $color-darkblue !important;
      }
    }

    &.special-box-flugs {
      background-image: url('../images/lp17-mobile-trees.png');
      background-position-x: right;
    }

    .hl-small {
      font-size: 1rem;
      line-height: 1.375;
      color: $color-darkblue;
      font-weight: 300;
      padding-bottom: 20px;
      display: block;
    }

    .hl-3 {
      color: $color-darkblue-text;
      padding-top: 20px;
      padding-bottom: 10px;
      font-size: 1.5rem;
      line-height: 1.15;
    }

    ul {
      list-style-type: none;
      padding-bottom: 15px;
      max-width: 85%;

      li {
        font-size: 1rem;
        line-height: 1.2;
        position: relative;
        padding: 5px 0 5px 5px;

        &:before {
          content: '';
          font-family: 'fontawesome';
          color: $color-darkblue-text;
          display: block;
          position: absolute;
          top: 2px;
          left: -20px;
          width: 10px;
          height: 14px;
          background-repeat: no-repeat;
          background-size: 10px 14px;
        }
      }
    }
  }

  .special-box-after {
    background: $color-primary-dark;
    padding: 10px;
    text-align: center;

    .morelink {
      font-size: .9rem;
      line-height: 1.5;
      color: $color-darkblue-text;
      font-weight: 400;
      margin-bottom: 0;
      position: relative;

      &:after {
        content: '';
        font-family: 'Linearicons-Free';
        font-size: 1.2rem;
        display: block;
        position: relative;
        margin: 0 auto;
        top: -8px;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-size: 15px 15px;
      }

      &.close {
        &:after {
          content: '';
        }
      }
    }
  }

  .argument {
    border: 0;
    padding-top: 20px;

    p {
      margin-top: 60px;
      clear: both;
    }
  }

  .h-with-img {
    font-size: 1.5rem;
    line-height: 1.3;
    vertical-align: baseline;
    position: absolute;
    padding-left: 60px;
    margin-top: 0;
    transform: translateY(0%);

    img {
      width: 60px;
      height: auto;
      margin-left: -60px;
      margin-top: -10px;
      padding-right: 10px;
      left: 0;
      float: left;
    }
  }

  &.long {

    .special-box-container {
      .benefits-list {
        max-height: 100%;
        opacity: 1;
      }
    }

    .special-box-inside {
      padding: 20px 20px 20px 25px;
      max-height: 300px;
      opacity: 1;
      background: $color-grey;
    }
  }

  //1024
  @include breakpoint(large) {
    .special-box-container {
      margin-left: -100px;
      margin-right: 50px;
      min-width: inherit;
    }

    .special-box-container {
      max-width: 37%;
    }
  }

}

#mobile-big-benefits {
  padding: 40px 0 25px;

  h2 {
    text-align: center;
    padding-bottom: 15px;
  }

  .h-with-img {
    display: inline-block;
    line-height: 1.2;
    padding-top: 10px;

    img {
      margin-top: -7px;
      width: 40px;
      margin-right: 10px;
      display: block;
      float: left;
    }
  }
}
