.slick-slider {
  .draggable {
    .slide {
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }
  }
}

.flickity-page-dots,
.slick-dots {
  bottom: 30px;
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;

  .dot,
  > li {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 8px;
    background-color: #333;
    border-radius: 50%;
    opacity: .25;
    cursor: pointer;

    &:first-child:last-child {
      display: none;
    }

    button {
      display: none;
    }

    &.is-selected,
    &.slick-active {
      opacity: 1;
      background-color: $color-white;
    }
  }
}



#mobile-slider {
  padding: 3rem 0 40px;

  .slide {
    text-align: center;

    p {
      @include scale-font(16, 18);
      color: $color-black;
      padding: 20px 50px;
    }

    img {
      display: inline-block;
      max-width: 200px;
    }

  }

  .flickity-page-dots,
  .slick-dots {
    bottom: 0;

    > li {
      &.is-selected,
      &.slick-active {
        opacity: 1;
        background-color: $color-primary;
      }
    }
  }
}

