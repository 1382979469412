.legalbox {
  box-shadow: inset 0 .2rem .2rem 0 rgba(0, 0, 0, .2);
  font-size: .6rem;
  padding: 23px 20px 5px;
  color: $color-grey-inactive;
  background: $color-grey-medium;

  @include breakpoint(small down) {
    padding: 20px;
  }

  p {
    line-height: 1.5;
  }

  .additional {
    font-size: .7rem;
    color: $color-darkblue-text;
    margin-bottom: .5rem;

    @include breakpoint(small down) {
      margin-bottom: 0;
    }
  }
}
