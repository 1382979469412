.jumbotron {
  transition: padding .25s;

  &.without-subnavi {
    .jumbotron-image {
      background-position: center 0;
    }
  }

  .jumbotron-image-wrapper {
    min-height: 630px;
    overflow: hidden;
    background: $color-darkblue;

    @include breakpoint(large) {
      & + .jumbotron-container {
        margin-top: -550px;
      }
    }

  }

  .jumbotron-image-wrapper {
    background: $white;
  }

  .container {
    overflow: visible;
  }

  .jumbotron-image {
    position: relative;
    min-height: 630px;
    background-image: url('../images/jumbotron-pk-visual-winter.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 40px;
    opacity: 0;
    transition: opacity 1s ease-out;

    &.ready {
      opacity: 1 !important;
    }

    .jumbotron-image-overlay {
      width: 100%;
      height: 100%;
      background: rgba($color-darkblue, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

  }

  //Blue Box
  .jumbo-calc-container {
    margin-top: 0;
    margin-bottom: 0 !important;

    @include breakpoint(large) {
      > .row {
        margin: 0;
      }
    }

  }

  .flickity-enabled.is-draggable .flickity-viewport {
    width: 100%;
  }

  .right-block {
    margin-left: 43%;
  }

  .jumbotron-content {
    position: relative;
    margin: 1.5rem 0 rem-calc(50px);
    color: $color-white;
    padding: 0;
    z-index: 2;

    .subline {
      @include scale-font(20, 26);
      font-weight: 300;
    }

    .h1 {
      //font-size: 3.4rem;
      font-size: rem-calc(60px);
      line-height: 1.1;
      font-weight: 300;
    }

    .jumbo-hl {
      margin-top: 30px;
      padding-top: 5px;
      font-size: rem-calc(26px);
      position: relative;
      height: 55px;

      span {
        padding: 5px 2px;
        display: inline-block;
        height: 55px;
        margin-bottom: -10px;
        background: url('../images/icon-rate-bg.svg') right 10px top no-repeat;
        background-size: 63px 50px;
      }

      span.big {
        font-weight: 400;
        font-size: 1.5em;
        color: $color-primary;
        padding: 5px 0;
        background-size: contain;
      }

      .strike {
        display: inline-block;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 43%;
          //transform: rotate(-10deg);
          border: 2px solid rgba($color-primary, 0.6);
        }
      }
    }

    .jumbo-hl-small {
      font-size: 20px;
    }

    .jumbo-hl-big {
      font-size: 40px;
      font-weight: 600;
    }

    h1,
    .subline,
    .jumbo-hl-big,
    .jumbo-hl-small,
    .jumbo-hl {
      color: $color-text;
    }

    .bubble {
      h1,
      .subline,
      .jumbo-hl-big,
      .jumbo-hl-small,
      .jumbo-hl {
        color: $color-white;
      }
    }

    @include breakpoint(medium down) {
      .jumbo-hl-big,
      .jumbo-hl-small {
        color: $color-white;
      }

      .jumbo-hl {
        font-size: rem-calc(20px);

        span {
          background-size: 53px 40px;
        }
      }
    }

    @include breakpoint(small down) {
      h1,
      .subline,
      .jumbo-hl-big,
      .jumbo-hl-small,
      .jumbo-hl {
        color: $color-white;
      }
    }

    p,
    h1 {
      margin: 0;
    }

    p {
      @include scale-font(16, 21);
      margin-bottom: 0.625rem;
    }

    //Blue Box
    &.jumbo-calc-teaser {
      position: relative;
      margin: 1.5rem 0 0;
      color: #fff;
      padding: 0;
      z-index: 2;
      font-size: rem-calc(26px);

      .callout {
        background: #ffd000;
        text-align: center;
        color: $color-primary;
      }
    }
  }

  .right-block {
    padding-left: 110px;
    padding-bottom: 30px;
  }

  .jumbotron-content {
    margin-bottom: 60px;
  }

  @include breakpoint(xlarge down) {

    .jumbotron-content {
      margin-bottom: 80px;
    }
  }

  @include breakpoint(large down) {
    .right-block {
      padding-left: 60px;
    }

    .jumbotron-content {
      margin-bottom: 80px;
    }
  }

  @include breakpoint(medium down) {

    margin-top: 43px;

    .right-block {
      padding-top: 20px;
      padding-left: 20px;
    }

    .jumbotron-content {
      margin-top: 10px;
      margin-bottom: 120px;
      padding: 0 15px;

      .h1 {
        font-size: 3rem;
      }

      .jumbo-hl {
        margin-top: 15px;
        padding-bottom: 40px;
      }

    }

    .flickity-page-dots,
    .slick-dots {
      bottom: 0;
    }

    .jumbotron-image-wrapper {
      min-height: 470px;

      & + .jumbotron-container {
        margin: -450px 0 0;
      }
    }

    .jumbotron-image {
      min-height: 470px;
      width: 100%;
      background-size: cover;
      background-position-y: 0;
    }

    .jumbo-calc-container {
      margin-top: -15px;

      .calc-container {
        h3 {
          font-size: 1.25rem;
          font-weight: bold;
        }
      }
    }

  }

  @include breakpoint(768px down) {

    .jumbotron-content {
      margin-bottom: 150px;
    }
  }


  @include breakpoint(small down) {
    margin-top: 0;
    display: block;
    height: 470px;
    overflow: hidden;

    .right-block {
      margin-left: 0;
      padding: 0;
    }

    .jumbo-hl {
      margin-top: 0;
      padding-bottom: 0;
    }

    .jumbotron-image-wrapper {
      min-height: 470px;

      & + .jumbotron-container {
        margin: -505px 0;
        //margin-top: 83px;
      }
    }

    .jumbotron-image {
      min-height: 470px;
      width: 100%;
      background-size: cover;
      background-position-x: -230px !important;
      background-position-y: 0;

    }

    .jumbotron-content {
      min-height: 470px;
      text-align: center;
      background: rgba(0, 0, 0, 0.2);
      margin-bottom: 50px;

      .h1 {
        font-size: 2.2rem;
        font-weight: 400;
        padding-top: 40px;
      }

      .jumbo-hl-small {
        font-size: .8rem;
        text-transform: uppercase;
      }

      .bubble {
        padding-top: 43px;
        text-align: center;
        width: 100%;
        min-height: 250px;
        position: relative;

        content: '';
        display: block;
        background: url('../images/lp17-bubble.png') center center no-repeat;

        .jumbo-hl-small {
          font-size: .9rem;
          text-transform: none;
          display: block;

          &.tax {
            margin-top: -25px;
          }

          &.link {
            color: $color-secondary;
            margin-top: 27px;
            position: relative;
            display: inline-block;
            line-height: 1;

            &:before {
              content: '';
              display: block;
              position: absolute;
              margin-top: 15px;
              width: 90%;
              border-bottom: 1px solid $color-secondary;
            }

            &:after {
              content: '';
              font-family: 'Linearicons-Free';
              font-size: .7rem;
              font-weight: bold;
              display: inline-block;
              position: relative;
              top: 0;
              right: -5px;
            }
          }
        }

        .jumbo-hl-big {
          font-family: 'handwritten';
          font-size: 3.9rem;
          display: inline-block;
          margin-top: -20px;

          &.percentage {
            font-size: 2.6rem;
          }
        }
      }

      .bubble-dummy {
        display: block;
        position: absolute;
        top: 0;
        z-index: 0;
      }
    }

  }

  .jumbo-badge {
    position: absolute;
    width: 18vh;
    top: 10%;
    right: 76vw;
    z-index: 10;
    max-width: 200px;
  }

  //BLUE BOX VERSION
  &.bluebox {
    @include breakpoint(small down) {
      height: 100%;

      .jumbotron-image-wrapper {
        min-height: 250px;
        position: relative;

        .badge {
          position: absolute;
          bottom: 0;
          font-size: 14px;
        }

        & + .jumbotron-container {
          margin: 0;
          background: $color-primary;
          padding: 0 10px;
        }
      }

      .jumbotron-image {
        min-height: 250px;
        //min-height: 100%;
        width: 100%;
        background-size: cover;
        background-position-y: 0;
        background-position-x: left !important;
      }


      .jumbotron-content {
        min-height: 100%;
        margin: 0;
        padding: 1rem 0 1.5rem;
        text-align: center;
        background: transparent;

        .h1 {
          font-size: 2.25rem;
          padding-top: 0;
          padding-bottom: 1rem;
          text-align: center;
        }

        .subline {
          @include scale-font(20, 26);
          padding: .5rem 0;
          margin: 0;
          text-align: center;
        }

        .jumbo-hl-small {
          padding: 0;
          margin: 0;
          font-size: 1.125rem;
          text-transform: none;
        }

        .jumbo-hl-big {
          font-size: 1.5rem;
        }

        .subline-tax {
          @include scale-font(20, 26);
          padding: 0;
          margin: 0;
          text-align: center;
        }

        a.btn.btn-primary {
          display: block;
          margin: 20px auto 10px;
          width: 90%;
          background: $color-secondary;
        }
      }
    }
    @media screen and (min-width: 500px) and (max-width: 767px) {
      .jumbotron-image {
        background-position-x: center;
      }
    }
  }

}

.jumbotron.is-full-width-image,
.jumbotron.has-beer-slider {
  height: calc(70vh - 85px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;


  .container {
    width: 100vw;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    transition: opacity 0.5s ease-out;

    &.needs-text-shadow {
      h1,
      h2 {
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      }

      &:before {
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, #6c6052 25%, rgba(0, 0, 0, 0) 75%);
        content: '';
        position: absolute;
        top: 65%;
        left: 0;
        right: 0;
        height: 45%;
        display: block;
        opacity: 0.5;
        z-index: 0;
      }
    }
  }

  > .container {
    z-index: 0;
  }

  #cta-section {
    padding: 0;
    margin: 0;
  }

  .jumbotron-content {
    margin: 0 auto;
    text-align: center;
    position: relative;
    top: 20%;
    //transform: translateY(-50%);
    background: transparent !important;

    @include breakpoint(small down) {
      top: 10vh;
    }

    .h1 {
      font-size: 82px;

      @include breakpoint(medium down) {
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        font-size: 62px;
      }
    }

    h1,
    h2,
    h3,
    p {
      color: $white !important;
    }
  }
}

.jumbotron.has-beer-slider {
  .beer-slider {
    opacity: 0;
    transition: opacity 0.5s ease-out;

    &.beer-ready {
      opacity: 1;
    }
  }

  .container {
    opacity: 0;
  }

  .beer-ready ~ .container {
    opacity: 1;
  }

  .beer-ready .container {
    opacity: 1;
  }

  @keyframes pulse {
    0% {
      transform: translate(-26px, -50%) scale(0.9);
      box-shadow: 0 0 0 0 rgba($color-secondary, 0.9), 0 0 0 0 rgba($white, 0.5);
    }

    70% {
      transform: translate(-26px, -50%) scale(1);
      box-shadow: 0 0 0 10px rgba($color-secondary, 0), 0 0 0 20px rgba($white, 0);
    }

    100% {
      transform: translate(-26px, -50%) scale(0.9);
      box-shadow: 0 0 0 0 rgba($color-secondary, 0), 0 0 0 0 rgba($white, 0);
    }
  }

  .beer-slider:not(.beer-start) {
    .beer-reveal {
      width: 5vw !important;
    }

    .beer-handle {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      left: 5vw !important;
      animation: pulse 2s infinite;
    }
  }

  .beer-slider.beer-interact {
    .beer-reveal {
      transition: none;
    }

    .beer-handle {
      transition: all 0.2s ease-out;
      animation: pulse 2s infinite;
    }
  }

  .beer-reveal {
    border-right: 4px solid $color-secondary;
    transition: width 1s ease-in-out;
  }

  .beer-range {
    height: 60px;
    top: calc(58% - 30px);
  }

  .beer-range:hover ~ .beer-handle {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    background: $color-secondary;
    transform: translate(-26px, -50%) scale(1.1) !important;
    animation-duration: 0s;
    transition: all 0.2s ease-out, left 0s;
  }

  .beer-handle {
    cursor: pointer;
    pointer-events: none;
    transform: translate(-26px, -50%);
    box-shadow: none;
    background: $color-secondary;
    top: 58%;
    transition: left 1s ease-in-out;
    animation: pulse 2s infinite;

    &:after,
    &:before {
      cursor: pointer;
      pointer-events: none;
    }
  }

  .beer-slider {
    width: 100%;

    img {
      width: 100vw !important;
      height: calc(70vh - 85px) !important;
      max-width: none;
      object-fit: cover;
      object-position: bottom;

      &.image-top {
        object-position: top;
      }

      &.image-center {
        object-position: center;
      }

    }

  }
}

.jumbotron.is-full-width-image {
  height: auto;

  .image-center {
    height: 50vh;
    min-height: 800px;
    width: auto;
    object-fit: cover;
    object-position: center top;

    @include breakpoint(small only) {
      min-height: 800px;
    }
  }

  .needs-text-shadow {
    &:before {
      top: 38% !important;
    }
  }

  .jumbotron-content {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

}

.jumbotron.is-split {
  max-height: 1100px;
  min-height: 800px;
  height: 80vh;
  position: relative;

  @include breakpoint(medium only) {
    height: 92vh;
    margin-bottom: 3rem;
  }

  &.sommerkredit2021 {
    //background-image: url('../images/jumbotron-sommerbild-2024.jpg');
    //background-image: url('../images/jumbotron-sommerkredit2021-visual-split.webp');
    background-image: url('../images/jumbotron-ratenkredit2021-visual-split.jpg');
    background-size: cover;
    background-position: center;

    @include breakpoint(medium down) {
      //background-image: url('../images/jumbotron-sommerbild-2024-mobile.jpg');
      //background-image: url('../images/jumbotron-sommerkredit2021-visual-split-mobile.webp');
      background-image: url('../images/jumbotron-ratenkredit2021-visual-split-mobile.jpg');
      background-position: center;
    }
  }

  > .container {
    position: absolute;
    bottom: 0;
    top: auto;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(large) {
      top: auto;
      bottom: 3vw;
    }
  }

  .jumbotron-content {
    position: absolute;
    top: auto !important;
    bottom: 3vw !important;
    left: 24vw !important;
    right: 4vw !important;
    max-width: 380px;
    margin: 0 !important;
    min-height: 0;
    background: transparent !important;

    @include breakpoint(medium) {
      left: 60vw !important;
    }

    @include breakpoint(large) {
      max-width: none;
      width: 20%;
      left: auto !important;
      top: auto !important;
      bottom: 3vw !important;
    }

    .cta {
      margin: 1rem 0;
      width: auto;
      display: block;

      @include breakpoint(medium) {
        display: inline-block;
      }
    }

    h1,
    h2,
    h3 {
      text-align: left;
      line-height: 1.3;
      margin-bottom: 0.5em;
      color: $white;

      a {
        color: inherit;
        white-space: nowrap;
        border-bottom: 1px dashed;
        font-size: 0.75em;
      }
    }

    h2 {
      font-size: 36px;
      line-height: 1.1;

      @include breakpoint(1600px) {
        font-size: 60px;
      }
    }

    h3 {
      font-size: 20px;
      line-height: 1.1;

      @include breakpoint(1600px) {
        font-size: 30px;
      }
    }

    &.is-first {
      left: 4vw !important;
      bottom: auto !important;
      top: 10% !important;

      @include breakpoint(medium) {
        left: 40% !important;
      }

      @include breakpoint(large) {
        position: relative;
        transform: translate(-50%, -50%) !important;
        width: 28%;
        left: 50% !important;
        right: auto !important;
        top: 45% !important;
        bottom: auto !important;
        max-width: none;
      }

      &:before {
        content: '';
        position: absolute;
        top: -50px;
        left: -50px;
        right: -50px;
        bottom: -50px;
        background: rgba($white, 0.75);
        filter: blur(70px);
        border-radius: 100%;
        z-index: -1;
      }

      h1,
      h2,
      h3 {
        color: $color-text !important;
        text-shadow: 0 0 2px rgba($white, 0.6);
      }
    }

  }
}

.jumbotron.is-full-width-image {
  height: auto;

  .image-center {
    height: 80vh;
    width: auto;
    object-fit: cover;
    object-position: center top;

    @include breakpoint(small only) {
      min-height: 800px;
    }
  }

  .needs-text-shadow {
    &:before {
      top: 38% !important;
    }
  }

  .jumbotron-content {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

}

.jumbo-benefits {
  background: $color-darkerblue;
  padding: 30px 0 0;
  text-align: center;

  .seals-slider {
    height: 100%;

    .slide {
      &:focus {
        outline: none;
      }
    }

    p {
      @include scale-font(16, 18);
      color: $white;
      padding-bottom: 3rem;
    }
  }

  @include breakpoint(medium down) {
    display: none;
  }

  .slider-container {
    height: 100%;
    padding: 0;

    .slide {
      padding-left: 25px;
      padding-right: 25px;

      @include breakpoint(xlarge down) {
        .jumbo-benefits-img {
          margin: 50px auto 0;
        }
      }

      @include breakpoint(large down) {
        .jumbo-benefits-img {
          margin: 20px auto;
          width: 160px;
          height: 134px;
        }
      }
    }
  }

  .jumbo-benefits-img {
    margin: 0 auto;
    display: block;
  }

  p {
    padding-top: 20px;
  }

  .slide {
    width: 100%;

    .jumbo-benefits-img {
      width: 170px;
      height: auto;
      margin: 20px auto 0;
      max-width: 60%;
    }

  }

}

.privatkredit,
.umzugskredit,
.hochzeitskredit {

  .jumbo-benefits {

    .slider-container {

      .slide {

        @include breakpoint(large down) {
          .jumbo-benefits-img {
            width: 75%;
            height: auto;
          }
        }
      }
    }
  }
}

.jumbotron-split {
  height: 60vh;
  min-height: 630px;
  overflow: hidden;
  position: relative;

  @include breakpoint(small down) {
    min-height: 0;
  }

  .jumbotron-content {
    margin: -10vh auto 0;
    height: 100%;
    align-content: center;

    @include breakpoint(small down) {
      background: $color-primary !important;
    }

    .h1 {
      font-size: rem-calc(36px);

      @include breakpoint(large) {
        font-size: rem-calc(50px);
      }
    }

    > .column {
      padding-left: 3vw;
    }
  }

  .jumbotron-image-wrapper {
    height: 100%;
    background: $color-darkblue;

    .jumbotron-image {
      position: relative;
      height: 100%;
      background-position: center top;

      .privatkredit & {
        background-image: url('../images/jumbotron-pk-visual-digital.jpg');
      }

      .sofortkredit & {
        background-image: url('../images/jumbotron-sk-visual.jpg');
        background-position: center top;
      }

      .sommerkredit & {
        background-image: url('../images/jumbotron-sommerkredit2021-visual.jpg');
        background-position: center top;
      }
    }


    @include breakpoint(medium) {
      width: 50%;
      position: absolute;
    }

  }

}


//*********************************
//   ***** MULTIPRODUCT *****
//*********************************

.jumbotron {
  &.multiproduct.bluebox {
    margin-top: -6rem;
    padding-top: 7rem;
    padding-bottom: 8rem;
    background: radial-gradient(#009ddf, #0181ba);
    background: radial-gradient(#7fceef, #009de0);
    text-align: center;
    max-height: 700px;

    .container {
      padding: 50px;
    }

    .jumbotron-content {

      .mp-logo {
        display: none;
      }

      h1 {
        font-size: rem-calc(70px);
        color: $color-white;
        padding: 4rem 0 0;
      }

      p {
        @include scale-font(16, 21);
        margin-top: 1rem;
        color: $color-white;
        padding-left: 14%;
        padding-right: 14%;
      }
    }

    @include breakpoint(large down) {
      .container {
        padding: rem-calc(25px) 0;
      }

      .jumbotron-content {
        p {
          padding-left: 10%;
          padding-right: 10%;
        }
      }
    }

    @include breakpoint(medium down) {

      .container {
        padding: rem-calc(75px) 0;
      }

      .jumbotron-content {

        p {
          padding-left: 10%;
          padding-right: 10%;
        }
      }
    }

    @include breakpoint(small only) {

      margin-top: -4rem;
      padding-top: 0;
      padding-bottom: 0;
      max-height: 100%;

      .container {
        padding: 1rem 0 .5rem;
      }

      .jumbotron-content {

        .mp-logo {
          width: 180px;
          margin: 1.5rem auto 2rem;
          display: block;
        }

        h1 {
          font-size: 2.25rem;
          padding: 0 0 .5rem;
        }

        p {
          @include scale-font(16, 21);
          text-align: center;
          padding: .5rem 1rem 0;
          margin: 0;
        }

        .jumbo-hl {
          height: 100%;
          margin: 0;
          padding: 0 0 1.5rem;
        }
      }
    }

  }

}


