.smcx-modal {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  border-radius: 0 !important;

  .smcx-modal-title {
    display: none;
  }

  .smcx-branding {
    display: none !important;
  }

  > .smcx-modal-content {
    height: calc(100% - 45px) !important;
  }

  .smcx-modal-footer {
    display: none !important;
  }

  .smcx-modal-close {
    background: transparent;

    &:after {
      content: '';
      font-family: FontAwesome;
      color: $white;
      font-size: 20px;
    }

    &:hover {
      background: transparent;
    }
  }

}