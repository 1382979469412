.conditions {
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-out, max-height .4s ease-out;
  z-index: 10;

  &.open {
    max-height: 1000px;
    opacity: 1;
    pointer-events: auto;
  }

  .condition-container {
    padding: 0;
  }

  .callout {
    margin-bottom: 0;
    padding-top: 50px;
    padding-bottom: 45px;
    background: $color-grey;
    border-top: 0;
    border-bottom: $color-grey-dark;
    border-left: $color-grey-dark;
    border-right: $color-grey-dark;
  }

  .infotext {
    font-size: 12px;
    line-height: 1.3;
    font-weight: 400;
    color: $color-grey-text;
    text-align: center;
    margin-top: 40px;
  }


  @include breakpoint(small only) {

    .callout {
      padding: 0;
      margin: 0;
    }
  }

  .mobile-conditons {
    padding-top: 0;

    .infotext {
      text-align: left;
      margin-top: 0;
      padding: 15px;
      padding-bottom: 0;
    }
  }

}

#hide-conditions {
  display: none;
}

#show-conditions {
  display: block;
}

#hide-conditions.close {
  display: block;
}

#show-conditions.close {
  display: none;
}


#conditions-overview {
  margin-top: 4rem;
  margin-bottom: 4rem;

  @include breakpoint(medium down) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  h1,
  h2,
  h3 {
    text-align: center;
  }

  h4 {
    margin-top: 3rem;
  }

  .conditions {
    max-height: 1000px;
    opacity: 1;
    pointer-events: auto;

    .callout {
      background: transparent;
      padding: 0;
      margin-left: -10px;
      margin-right: -10px;

      .infotext-desktop {
        padding-left: 10px;
        padding-right: 10px;
      }

      .table {
        font-size: 1rem;
        margin-bottom: 1rem !important;
      }
    }
  }
}
