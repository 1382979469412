
#service {
  padding: 4rem 0;

  .column {
    &.outercolumn {
      padding: 0;

      .column:first-child {
        padding-right: 50px;
        padding-left: 0;
      }

      .column:last-child {
        padding-left: 50px;
      }
    }
  }

  .contact {
    p {
      padding-top: 15px;
    }

    a {
      font-weight: 700;
      color: $color-primary;
    }

    .contact-icon {
      padding-top: 20px;

      img {
        float: left;
        margin-right: 20px;
      }

      .font-handwritten {
        margin-bottom: 0;
      }

      p {
        padding-top: 0;
        margin-top: -5px;
      }
    }
  }

  @include breakpoint(large down) {
    .contact {
      .contact-icon {
        .font-handwritten {
          font-size: 1.9rem;
        }
      }
    }

    .column {
      &.outercolumn {
        padding: 0;

        .column:first-child {
          padding-right: 20px;
        }

        .column:last-child {
          padding-left: 20px;
        }
      }
    }
  }

  @include breakpoint(medium down) {
    .column {
      &.outercolumn {
        padding: 0 1rem;
      }
    }
  }

  @include breakpoint(small down) {
    padding: 40px 0;

    .column {
      &.outercolumn {
        padding: 0;
      }
    }

    .contact {
      .contact-icon {
        img {
          width: 50px;
          margin-right: 10px;
        }
      }
    }
  }

  @include breakpoint(medium down) {

    padding: 3rem 0 0;

  }

}


//*********************************
//   ***** MULTIPRODUCT *****
//*********************************

#service {
  background: $color-white;
  padding-bottom: 5rem;

  h2 {
    color: $color-darkblue;
  }

  h3 {
    font-size: rem-calc(26px);
    font-weight: bold;
    color: $color-darkblue;
    padding: 3rem 0;
  }

  a.with-arrow {
    font-size: 1.25rem;
    font-weight: $font-weight-semibold;
    margin-top: 2rem;
    display: block;

    &:after {
      display: none;
    }
  }

  .accordion {
    .accordion-item {
      .accordion-title {
        font-size: rem-calc(20px);
        font-weight: 300;
        padding-top: 12px;
        padding-left: 1.5rem;

        &:before {
          font-size: 3rem;
          font-weight: $font-weight-semibold;
          margin-top: -20px;
        }
      }

      &.is-active {
        .accordion-title {
          &:before {
            margin-top: -23px;
          }
        }
      }
    }
  }

  .contact {
    p {
      font-size: 1.25rem;
      font-weight: 300;
      color: $color-darkblue;
    }

    a {
      font-weight: $font-weight-semibold;
    }

    .phone-headline {
      font-size: rem-calc(40px);
    }

    img {
      width: 115px;
    }
  }


}

