.page {
  transition: 0.4s transform 0.4s ease-in-out;
}

.loader {

  .overlay {
    transition: all 0.4s ease-in-out;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    //border-radius: 100%;
    background: rgba($color-primary, 0.7);
    position: fixed;
    top: 0;
    left: -100%;
    //transform: translate(-50%, -50%);
    //transform-origin: 0 0;
    opacity: 0;

  }

  .graphic {
    transition: all 0.4s cubic-bezier(1, 0.24, 0.19, 1.2);
    position: fixed;
    top: 45%;
    left: 49%;
    transform: translate(-50%, -50%) scale(0);
    z-index: 1000001;
  }

  .headline {
    display: none;
    text-align: center;
    color: $white;
    font-weight: $global-weight-bold;
    position: fixed;
    top: calc(45% + 90px);
    left: calc(49% + 15px);
    transform: translate(-50%, -50%);
    animation: headline 1s infinite ease-in-out;
    animation-direction: alternate;
    z-index: 1000001;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
    white-space: nowrap;
  }

}

.loading {
  overflow: hidden !important;

  .page {
    filter: blur(5px) grayscale(1);
    //transform: scale(0.98);
  }

  .loader {

    .overlay {
      opacity: 1;
      z-index: 1000000;
      transform: translateX(100%);
    }

    .graphic {
      transform: translate(-50%, -50%) scale(1);
    }

    .headline {
      display: inline-block;
    }
  }
}


/*------------------------------------------------------------------------------
clock Loader  / prefixfree
---------------------------------------------------------------------------------*/
.clock {
  display: inline-block;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  background: $color-darkblue;
  margin: 10px;
  border: 5px solid $white;
  position: relative;
  box-shadow: 0 5px 0 0 rgba(0, 0, 0, .3), inset 0 5px 0 0 rgba(0, 0, 0, .3), inset 0 0 0 34px $color-secondary;

  .minutes {
    width: 5px;
    height: 60px;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    box-shadow: inset 0 2px 0 0 $white, inset 0 30px 0 0 $color-darkblue;
    animation: clock 1.5s linear infinite;
  }

  .hours {
    width: 5px;
    height: 50px;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    box-shadow: inset 0 2px 0 0 $white, inset 0 25px 0 0 $color-darkblue;
    animation: clock 6s linear infinite;
  }
}

@keyframes clock {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes headline {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}
