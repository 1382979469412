// Creates a scalable font size based on given min/max font size
// Optionally change start/end breakpoints for this behaviour
// (Uses foundation breakpoints)
//
// @param Number $min-font-size               - Minimum font size in pixels (including px)
// @param Number $max-font-size               - Maximum font size in pixels (including px)
// @param String $bottom-breakpoint [medium]  - Starting Breakpoint for font scaling (optional, default medium)
// @param String $top-breakpoint [xxlarge]    - Ending Breakpoint for font scaling (optional, default xxlarge)

@mixin scale-font($min-font-size, $max-font-size, $bottom-breakpoint: 'medium', $top-breakpoint: 'xxlarge') {
  $fs-min-resolution: rem-calc(map-get($breakpoints, $bottom-breakpoint)) / 1rem;
  $fs-max-resolution: rem-calc(map-get($breakpoints, $top-breakpoint)) / 1rem;

  $fs-min-font-size-rem: rem-calc($min-font-size);
  $fs-max-font-size-rem: rem-calc($max-font-size);

  font-size: $fs-min-font-size-rem;

  @include breakpoint($bottom-breakpoint) {
    font-size: calc(#{$fs-min-font-size-rem} + (#{($fs-max-font-size-rem - $fs-min-font-size-rem) / 1rem}) * (100vw - #{$fs-min-resolution}rem) / (#{$fs-max-resolution} - #{$fs-min-resolution}));
  }

  @include breakpoint($top-breakpoint) {
    font-size: $fs-max-font-size-rem;
  }
}
