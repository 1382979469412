.top-teaser {
  top: 85px;
  opacity: 1;
  //transition: transform .25s, opacity .25s;
  transition: transform .25s;
  width: 100%;
  z-index: 8;
  box-shadow: 0 0 0.225rem 0 rgba(0, 0, 0, 0.25);
  background: $color-secondary;

  &::after {
    content: ' ';
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    border-style: solid;
    border-width: 22px 22px 0;
    border-color: $color-secondary transparent transparent;
  }

  &.sticky {
    position: fixed;
  }

  &.top-teaser-hide {
    transform: translate(0, -100px);
    //opacity: 0;
  }

  a {
    color: $color-darkblue;
  }

  p {
    padding-top: .8rem;
    margin-bottom: .6rem;
    font-size: rem-calc(18px);
    text-align: center;
    line-height: 1.3;
  }

  .bold {
    font-weight: 600;
  }

  @include breakpoint(large down) {
    p {
      font-size: 16px;
    }
  }

  @include breakpoint(medium down) {
    top: 55px;

    p {
      padding-top: .5rem;
      margin-bottom: .3rem;
      font-size: 14px;
    }
  }

  @include breakpoint(small only) {
    top: 50px;

    p {
      padding-top: .55rem;
      font-size: 13px;
    }
  }
}