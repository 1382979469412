//*********************************
//   ***** MULTIPRODUCT *****
//*********************************

#product-details {

  .container {
    padding: 0;

    row {
      padding: 0;
    }
  }

  .detail-box {
    background: $color-primary-light;
    border-radius: 20px;
    padding: 0 2.5rem 5rem;
    position: relative;
  }

  h2 {
    text-align: center;
    font-size: 60px;
    color: $color-darkblue;
    padding-bottom: 3rem;
  }

  h3 {
    font-size: 34px;
    color: $color-darkblue;
    padding: 2rem 0 0;
    margin: 0;
    white-space: nowrap;
  }

  p {
    font-size: 26px;
    line-height: 1.4;
    color: $color-darkblue;
    padding: 5px 0;
    margin: 0;
  }

  .box-link {
    width: 100%;
    display: block;
  }

  .highlight {
    font-size: 16px;
    font-weight: bold;
    padding: .5rem 1rem;
    background: $color-primary;
    color: $color-white;
    display: inline-block;
    margin: 0 auto;
    width: auto;
    float: none;

    //change this !!!!!
    display: table;
  }

  .details {
    padding-top: 1.5rem;
  }

  .small {
    font-size: 16px;
    font-weight: bold;
  }

  .big {
    font-size: 60px;
    line-height: 1;
  }

  .lined {
    border-bottom: 1px solid $color-primary;
    margin-bottom: .5rem;

    //white-space: nowrap;
  }

  .tile-arrow {
    margin-top: 1rem;
    float: right;
  }
}
