.afforestation-slider {
  &.modal {
    &-slick {
      max-width: 99%;
      margin-left: 1px;

      .slick-dots {
        & > li {
          background-color: $primary-color;
          opacity: .7;

          &.slick-active {
            background-color: $white;
            opacity: 1;
          }
        }
      }

      .slick-prev,
      .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 50%;
        display: block;
        width: 40px;
        height: 40px;
        padding: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: none;
        z-index: 1;
      }

      .slick-prev {
        left: 10px;
      }

      .slick-next {
        right: 10px;
      }

      .slick-prev:before,
      .slick-next:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-size: 40px 40px;
      }

      .slick-prev:before {
        background-image: url('../images/icon-slider-arrow-left-white.svg');
      }

      .slick-next:before {
        background-image: url('../images/icon-slider-arrow-right-white.svg');
      }
    }
  }
}

.modals-afforestation {
  overflow: hidden;
  top: 50% !important;
  transform: translateY(-50%);

  .caption {
    font-size: 20px;
    display: block;
  }

  .close-button {
    color: $white;
    right: 30px;

    .fa {
      font-size: 40px;
    }
  }
}
