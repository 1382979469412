a.btn {
  padding: 10px 15px;
  position: relative;
  font-weight: 600;
  transition: all .2s ease-out;

  &.btn-primary,
  &.btn-hollow {
    border: 3px solid transparent;
    font-size: 13px;
    text-transform: uppercase;
    background: $color-secondary;
    color: $color-darkblue !important;
    padding: 15px 28px 15px 15px;

    &:before {
      transition: color .25s ease-out;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 300;
      content: '';
      width: 23px;
      height: 11px;
      right: 1rem;
      background: url('../images/cta-arrow-dark.svg') center center no-repeat;
      background-size: 23px 11px;
    }

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transform: scale(1.02);
    }
  }

  &.btn-secondary {
    font-size: 13px;
    text-transform: uppercase;
    color: $color-primary;
    border: 1px solid $color-primary;
    padding: 11px 15px;

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transform: scale(1.02);
    }
  }

  &.btn-hollow {
    background: transparent !important;
    color: $white !important;
    border: 3px solid $white !important;

    &:hover {
      background: $white !important;
      color: $color-darkblue !important;

      &:before {
        color: $color-darkblue !important;
      }
    }
  }

  &.cta {
    font-size: .9rem;
    background: $color-grey;
    border: 0;
    //new style 2019
    border-radius: 7px;
    padding: 1.2rem 3rem 1rem;
    font-size: rem-calc(20px);
    font-weight: $font-weight-semibold;
    text-transform: none;

    &:before {
      background-size: 8px 15px;
      //new style 2019
      background-size: 23px 11px;
      display: none;
    }

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transform: scale(1.02);
    }

    &.cta-primary {
      font-size: 1.25rem;
      background: $color-secondary;
      display: block;
      text-align: center;
      position: relative;
      //float: right;
      margin-right: auto;
      margin-left: auto;
      width: 225px;

      &:before {
        font-size: .9rem;
        display: block;
      }

      &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        transform: scale(1.02);
      }
    }
  }

  @include breakpoint(small down) {

    &.cta {
      padding: 1.2rem 1rem 1rem;

      &.cta-primary {
        width: 100%;
      }
    }
  }

  &.btn-teaser {
    font-size: 16px;
    padding: 18px 32px 15px 15px;
    text-align: center;
  }

}

a.with-arrow {
  font-size: .9rem;
  color: $color-primary;
  position: relative;

  &:after {
    content: '';
    font-family: 'Linearicons-Free';
    font-size: .7rem;
    font-weight: bold;
    display: block;
    position: absolute;
    top: 0;
    right: -10px;
    width: 6px;
    height: 11px;
  }
}

.safetytext {
  font-size: rem-calc(13px);
  float: right;
  margin-top: 15px;
  margin-bottom: 25px;
  position: relative;
  clear: both;

  &:before {
    opacity: 0.7;
    content: '';
    margin-right: 5px;
    font-family: FontAwesome;
    font-size: 14px;
    position: relative;
    top: 1px;
  }
}

.morelink {
  cursor: pointer;
}

.text-link {
  color: inherit;
  position: relative;
  font-weight: bold;
  padding-right: 2em;

  &:hover {
    color: inherit;

    &:before {
      right: 0;
    }
  }

  &:before {
    transition: all .25s ease-out;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 300;
    content: '';
    width: 23px;
    height: 11px;
    right: 5px;
    background: url('../images/cta-arrow-dark.svg') center center no-repeat;
    background-size: 23px 11px;
  }
}

@include breakpoint(small down) {
  .safetytext {
    text-align: center;
    display: block;
    float: none;
    top: 5px;
    left: 3px;
    margin-top: 20px;

    &:before {
      position: relative;
      left: -6px;
      display: inline-block;
      top: 3px;
    }
  }
}
