#cta-section,
.trust-section {
  background: radial-gradient(at top, #7fceef, #009de0);
  padding: 30px 0 40px;

  .cta-container {
    text-align: center;

    div {
      padding: 20px 11px;
      display: inline-block;
    }
  }

  h1,
  h2 {
    color: $color-white;
    text-align: center;
    padding-bottom: 15px;
  }

  h1 {
    font-size: 3.75rem;

    @include breakpoint(medium down) {
      font-size: 2.5rem;
    }

    @include breakpoint(small down) {
      font-size: 2rem;
    }
  }

  h2 {
    font-size: 2rem;
  }

  .cta,
  .btn {
    font-size: 1.25rem;
    font-weight: $font-weight-semibold;
    display: inline-block;
    float: none;
    min-width: 225px;
    border: 0;

    &:before {
      top: 48%;
    }
  }

  .seals {
    margin-left: 25px;
    margin-bottom: 15px;
    display: block !important;

    img {
      max-height: 180px;
      max-width: 130px;
      margin: 0 10px;

      &:nth-child(2) {
        max-width: 140px;
      }

      &:nth-child(3) {
        max-width: 140px;
      }
    }
  }

  &.is-compact {
    background: $color-grey-light;

    .seals {
      img {
        max-width: none;
        height: 200px;
        width: auto;

        @include breakpoint(small down) {
          height: 140px;
          margin-bottom: 1rem;
        }
      }
    }

    &.no-background {
      background: transparent;
      padding-top: 5rem;
      padding-bottom: 5rem;

      a.btn.cta:not(.cta-primary) {
        background: $color-grey-light;

        &:hover {
          background: $color-grey-light;
        }
      }
    }

    h1,
    h2 {
      color: $color-text;
    }

    a.btn.cta:not(.cta-primary) {
      background: $white;

      &:hover {
        background: $white;
      }
    }

  }
}

//new style 2019
.lp-special-credit {
  &.lp-hochzeit,
  &.umzugskredit {
    #cta-section {

      .cta,
      .btn {
        font-size: 1.25rem;
        border-radius: 7px;
        text-transform: none;
      }
    }
  }
}


//####################
//CTA SECTION MOBILE
//####################

.cta-section-mobile {
  background: $color-primary;
  background: radial-gradient(at top, #7fceef, #009de0);
  padding: 30px 0 40px;

  .cta-container {
    padding: 0 1rem 1.5rem;
  }
}

#mobile-slider {
  &.cta-section-mobile {

    .slide {

      p {
        color: $color-white;
      }
    }
  }
}
