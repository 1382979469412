.lp-special-credit {

  .main,
  #intro {
    text-align: center;
    position: relative;

    h2 {
      @include scale-font(36, 60);
      line-height: 1.2;
    }
  }


  .main {
    @include breakpoint(xlarge down) {
      top: -2rem;
    }

    @include breakpoint(large down) {
      top: -4rem;
    }

    @include breakpoint(small only) {
      top: 1rem;
    }
  }




  .jumbotron {
    position: relative;
    background: $color-primary;
    min-height: 60vh;
    overflow: visible;

    @include breakpoint(medium down) {
      min-height: 0;
    }

    @include breakpoint(small only) {
      min-height: 0;
    }

    .jumbotron-content {
      text-align: center;
      padding-top: 80px;

      @include breakpoint(medium down) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
      }

      .subline {
        @include scale-font(20, 28);
        color: $color-white;
      }

      .h1 {
        @include scale-font(40, 90);
        color: $color-white;
      }
    }

    .jumbotron-content {
      margin-top: 0;
      padding-top: 35px;
    }

    .jumbotron-carousel {
      h1 {
        position: relative;
        margin: 0 auto;
        max-width: 745px;
      }
    }

    .jumbotron-carousel-visuals {
      position: relative;
      top: 35px;

      .carousel-cell {
        text-align: center;
      }

      img {
        display: inline-block;
        width: auto;
        height: 500px;

        @include breakpoint(medium down) {
          height: 250px;
        }
      }
    }

    &.jumbotron-with-video {

      .jumbotron-content {
        padding-top: 0;
        bottom: 80px;
        position: absolute;
        left: 50%;
        width: 100%;
        max-width: 745px;
        transform: translateX(-50%);

        h1 {
          height: 300px;
        }

        @include breakpoint(small only) {
          bottom: 20px;
          min-height: 0;

          h1 {
            padding-top: 10px;
            height: 160px;
          }
        }
      }

      .jumbotron-image-wrapper {
        position: relative;
        height: 60vh;

        @include breakpoint(small only) {
          height: 60vh;
        }

        video {
          min-width: 100%;
          min-height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .jumbotron-image {
        height: 60vh;
        background-color: #00384f;
        background-image: url('/images/video-bg-hochzeit.jpg');

        @include breakpoint(small only) {
          height: 60vh;
        }
      }

      .jumbotron-image-overlay {
        opacity: 1;
        height: 580px;
        bottom: 0;
        top: auto;
        background: linear-gradient(to bottom, rgba(0, 157, 223, 0) 0%, rgba(0, 156, 222, 0) 1%, rgba(0, 56, 79, 0.64) 100%);
      }

      .jumbotron-carousel {
        .carousel-cell {
          width: 100%;
          margin-right: 10px;
        }

      }

    }

  }

  #intro {
    padding: 70px 0;
  }

  .calculator-container {
    overflow: visible;
  }

  #calculator {

    &.jumbotron-overlap {
      position: relative;
      top: -70px;
      z-index: 2;
      margin-top: 0;
      margin-bottom: 0;
    }

    .calc-container {
      text-align: left;
    }
  }

  #benefits {
    padding-top: 3rem;
    padding-bottom: 80px;

    @include breakpoint(small only) {
      padding-bottom: 20px;
    }

    h2 {
      padding: 1rem 0 4rem;

      @include breakpoint(medium down) {
        padding: 1rem 0 3rem;
      }

      @include breakpoint(small only) {
        padding: 1rem 0 2rem;
      }
    }

    .benefits {
      text-align: left;

      p {
        @include scale-font(16, 21);
      }

      .media-object {
        display: flex;
        margin-bottom: 3rem;

        @include breakpoint(large down) {
          margin-bottom: 2rem;
        }

        @include breakpoint(small only) {
          flex-wrap: wrap;
          text-align: center;
          margin-top: 0;
          margin-bottom: 0;

          .thumbnail {
            display: block;
            padding: 0;
          }

          .media-object-section {
            padding: 0 !important;
            display: block;
            flex-grow: 1;

            &.main-section {
              order: 2;
            }
          }

          img,
          svg {
            height: 130px;
            max-width: 140px;
            float: none;
            width: auto;
            margin: 0 auto;
          }

        }

        .media-object-section {
          &:first-child {
            padding-right: 2rem;
          }
        }

        .thumbnail {
          padding-left: 6vw;
          padding-right: 6vw;
        }
      }
    }

  }

  #steps {
    .step {
      img,
      svg {
        margin: 0 0 .5rem;
      }

      p {
        color: $color-darkblue !important;
        font-size: rem-calc(21) !important;
        text-align: center;
      }
    }
  }

  #cta-section {
    padding: 80px 0;

    .btn-primary {
      //background: $color-secondary;
      padding: 15px 50px;
      font-size: rem-calc(15);

      &:hover {
        background: $color-cta-blue !important;
      }
    }

    .btn-hollow {
      padding: 15px 50px;
      font-size: rem-calc(15);
    }

    h2 {
      //@include scale-font(48, 60);
      line-height: 1.2;
    }
  }

  #incentive {
    background: $color-secondary;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: left;

    h6 {
      color: $color-darkblue;
      font-weight: 600;
      font-size: rem-calc(19);
    }

    .incentive-image {
      @include breakpoint(small only) {
        margin-bottom: 2rem;
        text-align: center;

        img {
          max-width: 200px;
        }
      }
    }

    @include breakpoint(small only) {

      text-align: center;

      p {
        font-size: 1rem;
      }
    }

  }

  .contact-icon {
    img {
      position: relative;
      top: -10px;
    }

    h2 {
      font-family: $body-font-family;
      font-size: rem-calc(32);
      font-weight: 300;
    }
  }

  &.lp-umzug {

    .jumbotron {
      .jumbotron-content {
        .h1 {
          //@include scale-font(31, 72);
          padding: 0 10px;
          min-height: 2.5em;
        }
      }

      &.jumbotron-with-video {
        .jumbotron-image {
          background-image: url('/images/video-bg-umzug.jpg');
        }
      }

    }
  }

  &.lp-liebenberg {

    .jumbotron {
      .jumbotron-content {
        .h1 {
          @include scale-font(31, 102);
          padding: 0 10px;
          min-height: 2.5em;
        }
      }

      &.jumbotron-with-video {
        .jumbotron-image {
          background-image: url('/images/jumbo-bg-liebenberg-new.jpg');
          background-position: 68%;
        }
      }

    }
  }
}
