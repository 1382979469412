.top-bar {
  background: $color-white;

  .top-bar-content {
    height: 140px;
    transition: all 0.2s ease-out;
  }

  &.top-bar-sub {
    background-color: $color-grey-light;
    position: fixed;
    top: 85px;
    width: 100%;
    z-index: 999;
    transition: top .25s ease-out;
    opacity: 0.95;

    &.top-bar-slim {
      top: 52px;
    }

    &.top-bar-hide {
      top: 0;
    }

    @include breakpoint(medium down) {
      top: 70px;
    }
  }

  .menu {
    background: transparent;
    text-align: left;

    &.products {

      li {
        border-right: 1px solid rgba($color-black, 0.1);
        padding-right: 20px;
        margin-right: 20px;
        vertical-align: bottom;

        @include breakpoint(small down) {
          padding-right: 10px;
          margin-right: 10px;
        }

        &:last-child {
          padding-right: 0;
          margin-right: 0;
          border-right: 0;
        }

        &.current {
          > a {
            font-weight: bold;
          }
        }

        > a {
          line-height: 1.7;
          font-size: rem-calc(15);
        }
      }

      @include breakpoint(medium down) {
        text-align: center;
      }

      @include breakpoint(small down) {
        li > a {
          font-size: rem-calc(13);
        }
      }
    }
  }
}


.top-bar.sticky {
  padding: 0;
  height: 85px;
  transition: height .25s;
  z-index: 1000 !important;

  .column {
    padding: 0;

    &.logo-column,
    &.nav-column {
      display: flex;
      height: 100%;
      align-items: center;
    }

    &.nav-column {
      justify-content: flex-end;
    }

    @include breakpoint(small down) {
      position: absolute;
      top: 0;
      max-width: 100%;
      width: 100%;
    }
  }

  .top-bar-title {
    width: 100%;
    padding: 0;
    transition: all 0.2s ease-out;
  }

  .top-bar-logo {
    width: 185px;
  }

  .menu {
    background: none;
    float: right;

    & > li > a img,
    & > li > a i,
    & > li > a svg {
      margin-top: -2px;
    }

    .text-item {
      transition: opacity .25s;
      opacity: 1;
    }

    .menu-item {
      padding-top: 17px;

      &.text-item {
        padding-top: 20px;

        &.number {
          padding-right: 20px;
        }

        & > a:not(.cta),
        & > p {
          font-size: rem-calc(18px);
          margin: 1rem;
          color: $color-primary;
          outline: none;
        }

        .cta {
          color: $color-text;
          font-size: rem-calc(18px);
          padding-left: 1em !important;
          padding-right: 1em !important;
          width: auto !important;

          &:before {
            display: none !important;
          }
        }
      }

      & > a,
      & > p {
        display: block;
        line-height: 1;

        &.btn {
          margin-top: 6px;

          &.btn-primary {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }

    }

  }

  .hamburger {
    @include hamburger($color-primary, $color-hover: $color-primary);
    width: 22px;
    height: 22px;
    top: 18px;
    left: 15px;
    position: absolute;

    &:after {
      box-shadow: 0 7px 0 $color-primary, 0 14px 0 $color-primary;
      border-radius: 10px 15px;
      height: 3px;
    }
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    margin-top: 0 !important;
    z-index: 8;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }

  //SLIM AFTER SCROLLING
  &.top-bar-slim {
    height: 65px;

    .top-bar-content {
      height: 65px;
    }

    .top-bar-title {
      padding-top: 0;
      padding-right: 0;
    }

    .top-bar-right {
      width: 100%;
    }

    .menu {
      .menu-item {
        padding-top: 8px;

        &.text-item {
          padding-top: 9px;

          .cta {
            @include breakpoint(medium down) {
              margin-top: 8px;
            }
          }
        }
      }
    }

    .btn.btn-secondary {
      span {
        display: none;
      }

      width: 35px;
      height: 33px;
      padding: 5px 10px;
      font-size: 20px;

      &:before {
        top: 8px;
        left: 10px;
      }
    }

    .btn.btn-primary {
      padding: 7px 25px 7px 10px !important;

      &:before {
        right: 10px;
      }
    }

    .topbar-sub-links {
      transform: translateY(-50px);
      opacity: 0;
      z-index: -1;
      pointer-events: none;
    }

    @include breakpoint(small only) {
      height: 88px;
    }

  }

  @include breakpoint(medium down) {
    height: 70px;

    &.top-bar-2019 .top-bar-content {
      max-width: 768px;
      height: 70px;
    }

    &.top-bar-slim .top-bar-content {
      height: 65px;
    }

    .top-bar-title {
      padding-top: 1px;
      padding-right: 0;
      padding-left: 10px;
    }

    .top-bar-right {
      //width: 200%;
      margin-top: -5px;
    }

    .menu.simple {
      li {
        margin-right: 2px;
      }
    }

    .menu {
      .menu-item {
        padding-top: 10px;
        padding-right: 10px;

        &.text-item {
          padding-top: 11px;

          &.number {
            padding-right: 0;
          }
        }

        &.phone-item {
          position: relative;

          .phone-icon {
            content: '';
            display: block;
            position: absolute;
            top: 23px;
            left: -7px;
            width: 15px;
            height: 20px;
            background-image: url('../images/lp17-phone.svg');
            background-repeat: no-repeat;
            background-size: 15px 20px;
          }
        }
      }
    }

    .btn.btn-primary {
      padding: 10px 25px 10px 12px;

      &:before {
        font-size: .75rem;
      }
    }

    .btn.btn-secondary {
      //padding: 12px 11px 8px 26px;

      &:before {
        top: 9px;
        left: 10px;
      }
    }
  }

  //Standard Mobile
  @include breakpoint(small down) {
    height: 52px;

    .top-bar-title {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      width: auto;
      display: block;
      margin: 0 auto;
      float: none;
    }

    .top-bar-right {
      width: 200%;
      margin-top: -5px;
    }

    .menu {
      .menu-item {
        padding-top: 8px;

        &.text-item {
          padding-top: 9px;
        }

        &.faq-item {
          position: absolute;
          right: 30px;
          top: -5px;

          &:after {
            content: '';
            position: absolute;
            top: 21px;
            right: 10px;
            height: 20px;
            width: 1px;
            display: block;
            border-right: 1px solid $color-line;
          }
        }

        &.phone-item {
          position: absolute;
          right: 4px;
          top: 15px;

          a {
            margin-top: -10px;
            height: 20px;
            width: 20px;
            display: block;
          }

          .phone-icon {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 20px;
            background-image: url('../images/lp17-phone.svg');
            background-repeat: no-repeat;
            background-size: 16px 20px;
          }
        }
      }
    }

    .btn.btn-secondary {
      //text-indent: -99999px;
      width: 35px;
      height: 33px;
      padding: 5px 10px;
      font-size: 20px;
      position: absolute;
      right: 15px;
      top: 4px;

      &:before {
        top: 8px;
        left: 10px;
      }
    }

    .btn.btn-primary {
      padding: 8px 25px 8px 10px;

      &:before {
        top: 10px;
        right: 14px;
      }
    }
  }

}


//*********************************
//   ***** NEW TOP (2019) *****
//*********************************

.top-bar {

  //*********************************
  //   ***** ALL 2019 *****
  //*********************************
  &.all {

    &.top-bar-slim {
      background: $color-white;
      color: $color-primary;

      .top-bar-right {
        padding-top: 0;
        transition: padding .25s;
      }

      &.sticky.sticky {
        box-shadow: 0 0 0.225rem 0 rgba(0, 0, 0, 0.25);
      }

      .mp-logo {
        display: none;
      }

      .logo {
        display: block;
        margin: 0;
      }

      img {
        width: 185px;
        padding: 0;
        margin: 0;
      }

      .top-bar-title {
        width: auto;
        z-index: 9;
      }

      .phone-icon {
        margin-top: 12px;

        &:before {
          content: ' ';
          background-image: url('/images/icon-phone24-blue.svg');
        }
      }

      .faq-icon {

        &:before {
          background-image: url('/images/icon-questionmark-blue.svg');
        }
      }

      &.sticky .menu {
        padding-left: 0;

        .menu-item.text-item {
          padding-top: 6px;

          > p,
          > a:not(.cta) {
            color: $color-primary;
          }

          &.number {
            padding-right: 0;
            margin-top: 7px;
            margin-left: 3rem;
          }

          &.phone-item {
            padding: 13px 0 0 45px;
          }
        }
      }
    }


  }

  //*********************************
  //   ***** MULTIPRODUCT *****
  //*********************************
  &.multiproduct {
    background: none;
    color: $color-white;

    .topbar-responsive-links {
      text-align: center;
    }

    .mp-logo {
      display: block;
      float: left;
    }

    &.sticky.sticky {
      box-shadow: none;
    }

    .logo {
      display: none;
      float: left;
    }

    img {
      width: 210px;
      margin-top: 0;
    }

    .current {
      a {
        font-weight: bold;
      }
    }

    p,
    a {
      color: $color-white;
      font-size: 20px;
      font-weight: normal;
    }

    .top-bar-title {
      padding-top: 0;
    }

    &.sticky .menu {
      float: none;
      text-align: center;

      .menu-item.text-item {
        padding-top: 0;

        > p {
          color: $color-white;
        }

        > a {
          color: $color-white;
        }

      }
    }

    .phone-icon,
    .faq-icon {
      position: relative;
      text-align: left;
      display: block;

      &:before {
        content: '';
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        left: -10px;
        transform: translateX(-100%);
        background-repeat: no-repeat;
        background-position-x: right;
      }

    }

    .phone-icon {
      &:before {
        background-image: url('/images/icon-phone24-white.svg');
      }
    }

    .faq-icon {
      &:before {
        background-image: url('/images/icon-questionmark.svg');
      }
    }


    @include breakpoint(large down) {

      p,
      a {
        font-size: 1rem;
      }

      .menu .menu-item.text-item.number {
        padding-right: 0;
        margin-right: -1rem;
      }

    }

    @include breakpoint(medium down) {

      img {
        width: 180px;
      }
    }

    @include breakpoint(small only) {

      .top-bar-title {
        width: 180px;
        padding-left: 0;
      }

      .mp-logo {
        //float: none;
        //margin: 0 auto;
        display: none;
      }

      &:not(.top-bar-slim) {
        .hamburger {
          @include hamburger($color-white, $color-hover: $color-white);
          width: 22px;
          height: 22px;
          top: 18px;
          left: 15px;
          position: absolute;

          &:after {
            box-shadow: 0 7px 0 $color-white, 0 14px 0 $color-white;
            border-radius: 10px 15px;
            height: 3px;
          }
        }
      }
    }

    &.top-bar-slim {

      .top-bar-right {
        .menu {
          text-align: right;
        }
      }

      @include breakpoint(small only) {
        .logo {
          float: none;
          margin: 0 auto;
        }
      }
    }

  }

  //*********************************
  //   ***** SUBPAGES 2019 *****
  //*********************************
  &.top-bar-2019 {
    background: $color-white;

    .top-bar-content {
      height: 85px;
    }

    .mp-logo {
      display: none;
    }

    .top-bar-logo {
      float: left;
    }

    img {
      padding-top: 0;
      width: 185px;
      margin-top: 0;
    }

    .current {
      a {
        font-weight: bold;
      }
    }

    p,
    a {
      font-size: 20px;
      font-weight: normal;
    }

    .top-bar-title {
      padding-top: 0;
    }

    &.sticky .menu {

      &.left {
        float: none;
      }

      padding-left: 5rem;
      display: inline;

      .menu-item.text-item {
        padding-top: 0;
      }
    }

    .phone-icon,
    .faq-icon {
      position: relative;
      text-align: left;

      &:before {
        content: '';
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        left: -10px;
        transform: translateX(-100%);
        background-repeat: no-repeat;
        background-position-x: right;
      }
    }

    .phone-icon {
      &:before {
        background-image: url('/images/icon-phone24-blue.svg');
      }
    }

    .faq-icon {
      &:before {
        background-image: url('/images/icon-questionmark-blue.svg');
      }
    }

    @include breakpoint(xlarge down) {

      &.top-bar.sticky {

        .menu .menu-item.text-item.number {
          padding-right: 0;
          margin-right: -1rem;
        }
      }
    }


    @include breakpoint(large down) {
      a,
      p {
        font-size: 1rem;
      }
    }


    @include breakpoint(medium down) {

      img {
        padding-top: 0;
      }

      &.top-bar.sticky {

        .menu {
          padding-left: 0;

          .menu-item {
            padding-top: rem-calc(10px);
          }
        }
      }
    }


    @include breakpoint(small only) {

      &.top-bar {
        &.sticky {
          .top-bar-logo {
            float: none;
          }
        }
      }
    }

  }
}

.topbar-sub-links {
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 135px;
  opacity: 1;
  transition: all .25s ease-out;

  .menu-item > a,
  .menu-item > p {
    color: $white !important;
  }

  .menu-item {
    border-left: 1px solid $color-white;
    padding-left: 1rem;

    &:first-child {
      border: 0;
    }
  }
}

.top-bar.sticky .topbar-sub-links .menu .menu-item.text-item > a,
.top-bar.sticky .topbar-sub-links .menu .menu-item.text-item > p {
  font-size: rem-calc(21px);
  margin: rem-calc(5px);
  color: $white;
  font-weight: 400;

  &:hover {
    font-weight: bold;
    margin-left: 0;
    margin-right: 0;
  }
}

.top-bar {
  &.all {
    .nav-mobile-number {
      p,
      a {
        font-size: rem-calc(15px);
        color: $color-primary;
        z-index: 10;
      }
    }
  }

  &.top-bar-slim {

    @include breakpoint(small only) {
      .nav-mobile-number {
        display: block;

        p {
          margin-top: 5px;
        }
      }
    }

  }
}

.nav-mobile-number {
  display: none;

  p {
    margin: 0;
    margin-top: 10px;
    text-align: center;
  }

  .btn-faq {
    position: absolute;
    right: 18px;
    top: 18px;
    display: block;
    color: #009ddf;
    width: 20px;
    cursor: pointer;

    &:before {
      content: ' ';
      background-image: url('/images/amp-images/icon-faq.svg');
      width: 22px;
      height: 22px;
      display: block;
      position: absolute;
      margin-top: -2px;
      top: 50%;
      left: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      background-repeat: no-repeat;
    }
  }

}
