#trustpilot {
  background: $color-grey-light;
  padding: 5rem;

  h2 {
    color: $color-darkblue;
    text-align: center;
  }

  h3 {
    color: $color-darkblue;
    margin: 0;
    padding: .5rem 0 .2rem;
  }

  img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }

  p {
    @include scale-font(16, 21);
    color: $color-darkblue;
    padding: 0;
    margin: 0;
  }

  a.logo {
    display: block;
    //max-width: 280px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .trustpilot-logo {
    max-width: 150px;
    display: block;
    margin: 1rem auto 0;
  }

  .rating-stars {
    //max-width: 280px;
    height: 50px;
    display: inline-block;
    margin: 1rem auto;

    @include breakpoint(small down) {
      height: auto;
      max-width: 100%;
      text-align: center;
    }
  }

  .preview-rating-stars {
    max-width: 130px;
    height: 25px;
    display: block;
    margin: 1rem auto 5px;

    &.small {
      max-width: 120px;
    }
  }

  .rating {
    float: left;
    margin-right: .5rem;
    display: block;
    height: 40px;

    @include breakpoint(small down) {
      float: none;
      margin-bottom: 0.5rem;
    }

    p {
      transform: translateY(4px);
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .star-item {
    width: 40px;
    background-color: #73cf11;
    float: left;
    margin-left: 5px;

    &.big {
      width: 217px;
      background: transparent;

      @include breakpoint(small down) {
        float: none;
        display: inline-block;
      }
    }

    &.small {
      width: 20px;
      background-color: #00b67a;
      margin-left: 3px;
    }

    &.grey {
      background-color: #dcdce6;
    }
  }

  .line {
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin: rem-calc(17px) auto rem-calc(12px);
  }

  .reviews {
    display: block;
    clear: left;
    margin: 2rem auto;
    padding-bottom: 2rem;
    text-align: center;
    padding: 0 10% 1rem;
  }


  //Slider

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: none;
    z-index: 1;
  }

  .slick-next {
    right: 10px;
  }

  .slick-prev:before,
  .slick-next:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .slick-prev:before {
    background-image: url('../images/icon-slider-arrow-left.svg');
  }

  .slick-next:before {
    background-image: url('../images/icon-slider-arrow-right.svg');
  }

  .slick-dots {
    bottom: 0;
    margin-left: -15px;

    > li {
      background-color: #ccc;
      opacity: 1;
      transition: all 0.2s ease-out;
    }

    > li.slick-active {
      opacity: 1;
      background-color: $color-primary;
    }
  }

  @include breakpoint(large down) {
    padding: 4rem;
  }

  @include breakpoint(small only) {
    padding: 3rem 1rem;

    .mobile-bugfix {
      display: ruby;
    }

    h3 {
      line-height: 1.2;
    }

    .rating {
      p {
        padding-top: 0;
      }
    }

    p {
      padding-top: 1rem;
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 320px) {

    .rating {
      p {
        transform: translateY(2px);
      }
    }

    .star-item {
      width: 35px;
    }
  }
}

#steps + #trustpilot {
  margin-top: 5rem;
}
