
.stickyphone {
  position: fixed;
  display: none;
  width: 50px;
  height: 50px;
  content: '';
  bottom: 0;
  left: 50%;
  margin-left: 0;
  background-image: url('../images/lp17-sticky-phone.png');
  background-size: 50px 50px;
  background-repeat: no-repeat;
  text-indent: -9999999px;
  opacity: 1;
  z-index: 999;
  @include breakpoint(small down) {
    display: block;
  }
}

.stickyfaq {
  position: fixed;
  display: none;
  width: 50px;
  height: 50px;
  content: '';
  bottom: 0;
  left: 50%;
  margin-left: -50px;
  background-image: url('../images/lp17-sticky-faq.png');
  background-size: 50px 50px;
  background-repeat: no-repeat;
  text-indent: -9999999px;
  opacity: 1;
  z-index: 999;
  @include breakpoint(small down) {
    display: block;
  }
}
