#benefits,
#benefits-skg {
  margin-top: 0;
  padding: 3rem 0;

  &.subpage {
    padding: 4rem 0 0;
  }

  .container {
    overflow: visible;
  }

  h2 {
    padding: rem-calc(20px) 0;
    color: $color-darkblue;
    text-align: center;

    + p.subline {
      margin-top: -2rem;
      margin-bottom: 4rem;
    }
  }

  p.columns-2 {
    @include breakpoint(medium) {
      column-count: 2;
      text-align: left !important;
    }
  }

  h3 {
    color: $color-darkblue;
  }

  svg {
    float: left;
    margin-right: 1.5rem;
    height: 170px;
    width: auto;
    max-width: 225px;

    [fill='#ffffff'],
    [fill='#FFFFFF'] {
      fill: $color-primary-light;
      opacity: 1;
    }

    [fill='#00384F'],
    [fill='##00384f'] {
      fill: $color-primary;
      opacity: 1;
    }
  }


  &.is-compact {
    svg {
      display: block;
      float: none;
      max-width: inherit;
      height: 100px;
      margin: 0 auto 1rem;
    }
  }

  p {
    @include scale-font(16, 21);
    font-weight: 300;
    color: $color-darkblue;
    padding: 0;
    margin: 0 0 2rem;

    &.subline {
      margin: 0;
      padding: 0;
      text-align: center;
    }
  }

  .special-box-container {
    flex: 0 0 37%;
    padding: 0;
    margin-left: -100px;
    margin-right: 50px;
    max-width: 37%;
    min-width: inherit;
  }

  .special-box {
    padding: 50px 60px;
    background: $color-secondary url('../images/lp17-pyramids.png') bottom right no-repeat;
    position: relative;

    &.special-box-flugs {
      background-image: url('../images/lp17-trees.png');
      background-position-x: center;
    }

    &.hochzeit-incentive {
      text-align: center;
      background-image: none;
    }

    .incentive {
      position: relative;
      height: 200px;
      width: 100%;

      img {
        position: absolute;
        max-height: 85%;
        top: 42%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .icon {
        right: 0;
      }

      .gutschein {
        left: 0;
      }

    }

    &.hochzeit {
      background-image: url('../images/bg-special-box-hochzeit.png');
      background-position: 69% 100%;
    }

    &.special-box-flugs {
      background-image: url('../images/lp17-trees.png');
      background-position-x: center;
    }

    .hl-small {
      color: $color-darkblue-text;
      font-weight: 400;
      display: block;
    }

    .hl-3 {
      color: $color-darkblue-text;
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 1.875rem;
      line-height: 1.15;
    }

    .btn-primary {
      background-color: $color-primary !important;
      color: $color-white !important;
      display: inline-block;
      margin-bottom: 20px;

      &:hover {
        background-color: $color-darkblue !important;
      }
    }

    ul {
      padding-bottom: 30px;
      list-style-type: none;

      li {
        font-size: 1.25rem;
        color: $color-darkblue;
        position: relative;
        padding-left: 7px;
        line-height: 1.3;
        margin-bottom: 0.6em;

        &:before {
          content: '';
          font-family: 'fontawesome';
          color: $color-darkblue-text;
          display: block;
          position: absolute;
          top: -2px;
          left: -20px;
          width: 10px;
          height: 14px;
          background-repeat: no-repeat;
          background-size: 10px 14px;
        }
      }
    }

    &:after {
      content: '';
      top: 50%;
      right: -1px;
      display: none;
      position: absolute;
      width: 17px;
      height: 33px;
      background: url('../images/lp17-bg-arrow.png') center center no-repeat;
      background-size: 100%;
    }
  }

  .special-box-after {
    background: $color-primary-dark;
    padding: 20px 20px 20px 60px;

    .infotext {
      font-size: .85rem;
      line-height: 1.5;
      color: $color-darkblue-text;
      font-weight: 400;
      margin-bottom: 0;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -48px;
        left: -15px;
        width: 40px;
        height: 40px;
        background-image: url('../images/lp17-icon-info.png');
        background-repeat: no-repeat;
        background-size: 40px 40px;
      }
    }
  }

  .arguments {
    margin-left: -20px;
  }

  .argument {
    padding-top: 20px;
    padding-left: 20px;

    &.is-green {
      svg {
        [fill='#00384F'],
        [fill='#00384f'] {
          fill: $color-tertiary;
        }

        [fill='#FFFFFF'],
        [fill='#ffffff'] {
          fill: rgba($color-tertiary, 0.3);
        }
      }
    }

    &:nth-child(even) {
      padding-left: 2rem;
    }

    p {
      clear: both;
    }
  }

  .h-with-img {
    font-size: 1.5rem;
    line-height: 1.3;
    vertical-align: baseline;
    position: absolute;
    padding-left: 60px;
    margin-top: 0;
    transform: translateY(0%);

    img {
      width: 60px;
      height: auto;
      margin-left: -60px;
      margin-top: -10px;
      padding-right: 10px;
      left: 0;
      float: left;
    }
  }

  //1024
  @include breakpoint(large down) {

    padding: 2rem 0;

    h2 {
      padding: 0;
    }

    .special-box-container {
      margin-right: 20px;
      margin-left: -60px;
    }

    .h-with-img {
      font-size: 1.2rem;
    }

    .argument p {
      margin-top: 0;
    }

    .special-box {
      ul {
        li {
          font-size: 1rem;
        }
      }

      .hl-3 {
        padding-bottom: 10px;
      }
    }
  }

  //Tablet
  @include breakpoint(medium down) {

    padding: 1rem 0;

    h2 {
      text-align: center;
    }

    .special-box-container {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      min-width: 100%;
      margin-bottom: 25px;
      padding: 0 10px;
    }

    .special-box {
      padding: 30px;

      ul {
        padding-bottom: 0;
      }

      &:after {
        background: none;
      }
    }
  }

  //VARIANTE LONG
  &.long {
    overflow: hidden;
    position: relative;

    .headlines {
      text-align: center;
    }

    h2 {
      text-align: center;
      color: $color-primary;
    }

    .special-box {
      background: $color-secondary url('../images/lp17-big-pyramids.png') bottom right no-repeat;
      background-size: 770px 235px;
      position: relative;
      margin-bottom: 20px;

      &.special-box-flugs {
        background-image: url('../images/lp17-big-trees.png');
        background-position-y: bottom 15px;
      }


      &:after {
        background: none;
        //position: relative;
        //width: 100%;
        //height: 200px;
      }

      .hl-small {
        font-size: 1.125rem;
      }

      ul {
        text-align: left;
        padding-bottom: 0;
        margin-bottom: 5px;

        li {
          font-size: 1.25rem;
          line-height: 1.2;
          padding-bottom: 10px;
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    .special-after-container {
      width: 100%;

      .row {
        max-width: 100%;
      }
    }

    .special-box-after {
      background: $color-grey;
      padding: 20px 20px 20px 30px;
      width: 100%;
      margin-left: 48%;
      margin-top: -40px;
      position: absolute;

      .infotext {
        font-size: .85rem;
        line-height: 1.5;
        color: $color-darkblue-text;
        font-weight: 400;
        margin-bottom: 0;
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -31px;
          left: -45px;
          width: 40px;
          height: 40px;
          background-image: url('../images/lp17-icon-info.png');
          background-repeat: no-repeat;
          background-size: 40px 40px;
        }
      }
    }

    .text-box {
      text-align: left;
      flex-flow: initial;
    }

    .arguments {
      padding-top: 40px;
      padding-top: 0;
    }

    @include breakpoint(large down) {
      .special-box-after {
        margin-left: 30%;
      }
    }

    @include breakpoint(medium down) {
      text-align: left;

      .special-box {
        padding-top: 40px;
        padding-bottom: 60px;

        .hl-3 {
          font-size: 1.7rem;
        }

        ul {
          li {
            font-size: 1.1rem;
            line-height: 1.2;
            padding-bottom: 10px;
            margin-top: 15px;
          }
        }

        p {
          font-size: .85rem;
        }
      }

      .special-box-after {
        margin-left: 20px;
      }

      .arguments {
        padding-top: 0;
      }
    }

  }

  .container {

    &.withoutspecial {
      margin-top: -40px;
    }
  }


  @include breakpoint(medium only) {

    h3 {
      font-size: 1.7rem;
      hyphens: auto;
    }

    .container {
      padding: 0 1rem;
    }

    .argument {
      &:nth-child(even) {
        padding-left: 1rem;
      }
    }

  }

  @include breakpoint(small only) {
    padding: 1rem;

    &.subpage {
      padding: 2rem 0 0;
    }

    h2 {
      padding: 1rem 0;
    }

    h3 {
      font-size: 1.7rem;
      hyphens: auto;
      line-height: 1.3;
    }

    .argument:nth-child(even) {
      padding: 1rem;
    }

    .argument:nth-child(odd) {
      padding-right: 1.3rem;
    }

  }
}



//*********************************
//   ***** MULTIPRODUCT *****
//*********************************

.multiproduct,
.privatkredit {
  #benefits,
  #benefits-skg {
    p {
      padding: 1rem 0;
    }

    svg {
      max-width: 122px;
      height: auto;
    }

    &.is-compact {
      svg {
        display: block;
        float: none;
        max-width: 90px;
        height: 100px;
        margin: 0 auto 1rem;
      }
    }

    @include breakpoint(medium down) {
      svg {
        display: block;
        float: none;
        margin: 1rem auto;
        max-width: 80px;
        height: 80px;
      }
    }

    @include breakpoint(small only) {

      padding-bottom: 0;

      p {
        padding-top: .5rem;
      }

      .argument {
        padding-top: 0;

        &:nth-child(even) {
          padding-bottom: 0;
        }
      }

      svg {
        max-width: 100px;
      }
    }
  }
}

.multiproduct {
  #benefits {

    @include breakpoint(small only) {
      h2 {
        padding: 2rem 0 1rem;
      }

      .argument {
        text-align: center;
      }

      svg {
        float: none;
        margin: 0 auto 1rem;
        display: block;
      }
    }
  }
}

.privatkredit {
  #benefits {

    h2 {
      padding-bottom: 2rem;
    }

    .argument {
      &:nth-child(odd) {
        padding-right: 2rem;
      }
    }

    @include breakpoint(medium down) {
      //padding: 0;

      h2 {
        padding: 0 0 2rem;
      }
    }

    @include breakpoint(small only) {
      padding: 2rem 0.6rem 0;

      p {
        margin: 0 0 1rem;
      }
    }

  }
}

