section {
  .container {
    > h2 {
      text-align: center;
    }
  }
}

#benefits {
  &.long {
    padding: 40px 0;
  }
}

#faq-preview {
  padding: 10px 10px 2rem;

  h2 {
    margin-top: 1rem;
  }

  @include breakpoint(medium) {
    padding: 10px 0 2rem;

    h2 {
      margin-top: 2rem;
    }
  }
}

#calcdummy {
  background: $color-primary-light;
}

.container {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;

  //1200
  @include breakpoint(xlarge down) {
    width: 1100px;
  }
  //1024
  @include breakpoint(large down) {
    max-width: 960px;
    width: 100%;
  }
  //640px
  @include breakpoint(medium down) {
    width: 100%;
    max-width: 768px;
  }
  @include breakpoint(small down) {
    width: 100%;
  }
}

#calculator-big {
  margin: 3rem 0 0;

  h2 {
    margin-bottom: 2rem;
  }

  > img {
    width: 100%;
    object-fit: cover;
    max-height: 80vh;
  }

  .container {
    overflow: visible;
  }

  #calculator {
    position: relative;
    z-index: 1;

    @include breakpoint(medium) {
      margin-top: -10rem;
      box-shadow: 0 -20px 20px -10px rgba(0, 0, 0, 0.3);
    }
  }
}

#wishes {
  margin: 3rem 0;

  h2 {
    margin-bottom: 4rem;
  }

  p {
    @include scale-font(16, 24);
    font-weight: 300;
    color: $color-darkblue;
    padding: 0;
    margin: 1em 0;
  }

  p,
  img {
    width: 435px;
    max-width: 100%;
    display: inline-block;
  }

  .wishes-left {
    padding-right: 2vw;
    text-align: right;
  }

  .wishes-right {
    padding-left: 2vw;
    text-align: left;
  }

  .wish {
    margin-bottom: 4rem;

    &:first-child {
      p:first-child {
        margin-top: 4em;
      }
    }
  }
}

section.product-tiles {
  margin-top: 5rem;
  margin-bottom: 5rem;

  &.has-background {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: $color-grey-light;
  }
}
