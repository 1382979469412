
.teaser {
  height: 85vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  position: relative;

  &.teaser-liebenberg {
    background-image: url('/images/teaser-liebenberg-image.jpg');
  }

  h2 {
    font-size: 3.75rem;
    line-height: 1;
    padding: 8% 0 0;
    color: $color-white;
  }

  p {
    color: $color-white;
    padding: 1rem 5rem;
  }

  .logo {
    max-height: 15vh;
    display: inline-block;
  }

  .btn-teaser {
    margin-top: 4rem;
    min-width: 200px;
    display: inline-block;
  }

  .badge {
    position: absolute;
    max-height: 15vh;
    max-width: 200px;
    width: auto;
    left: calc(50% - 100px);
    bottom: 0;
  }


  @include breakpoint(large) {
    h2 {
      padding: 8% 0 0;
    }

    p {
      font-size: rem-calc(21px);
    }
  }

  // laptop
  @media screen and (max-width: 1440px) and (max-height: 768px) {
    h2 {
      padding: 5% 0 0;
    }

    p {
      padding: 1rem 0 0;
    }

    .btn-teaser {
      //font-size: 16px;
      margin-top: 2.5rem;
      padding: 15px 32px 12px 15px;
    }
  }

  @media screen and (min-width: 1441px) and (max-height: 850px) {
    .btn-teaser {
      display: none;
    }
  }

  @media screen and (max-width: 768px) and (max-height: 1024px) {
    .btn-teaser {
      display: inline-block;
    }
  }

  // iPad quer
  @media screen and (max-width: 1024px) and (max-height: 768px) {

    p {
      font-size: rem-calc(16px);
      line-height: 1.3;
    }
  }

  @include breakpoint(medium down) {
    h2 {
      font-size: 2.5rem;
      padding: 5% 0 0;
    }

    p {
      padding: 1rem 0 0;
    }
  }

  @include breakpoint(small only) {
    height: 80vh;

    h2 {
      font-size: 2rem;
      padding: 2.5rem 1rem 0;
    }

    .btn-teaser {
      display: none;
    }

    .badge {
      max-height: 95px;
    }
  }

  // iPhone X
  @media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .btn-teaser {
      display: inline-block;
      margin-top: 35px;
    }
  }

  // iPhone 5 and smaller
  @media only screen and (max-device-width: 320px) {

    h2 {
      font-size: 1.5rem;
    }

    p {
      line-height: 1.3;
      font-size: 14px;
      padding-top: 3%;
    }
  }
}

//*********************************
//   ***** MULTIPRODUCT *****
//*********************************

.teaser-2019 {
  background: $color-yellow-bg;
  background: url('../images/teaser-2019-bg.png'), radial-gradient(at 50% 30%, #ffe571, #ffd000);
  background-repeat: no-repeat;
  background-position: bottom center;
  margin-top: 0;
  padding: 4rem 0;
  text-align: center;

  h2 {
    font-size: rem-calc(60px);
    color: $color-darkblue;
    line-height: 1.2;
    padding-bottom: 3rem;

    + p {
      margin-top: -3rem;
      margin-bottom: 2rem;
    }
  }

  h3 {
    font-size: rem-calc(26px);
    font-weight: $font-weight-semibold;
    color: $color-darkblue;
    padding: 3rem 0;
  }

  p {
    font-size: rem-calc(20px);
    font-weight: 300;
    color: $color-darkblue;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .step-1 {
    padding: 0 2rem 0 0;
  }

  .step-2 {
    padding: 0 1.5rem;
  }

  .step-3 {
    padding: 0 0 0 2rem;
  }


  svg {
    height: 90px;
    margin-bottom: 1rem;
  }

  @include breakpoint(large down) {
    h2 {
      font-size: 3.25rem;
    }
  }

  @include breakpoint(medium down) {
    h2 {
      padding-bottom: 2rem;
    }

    p {
      margin-bottom: 0;
    }
  }

  @include breakpoint(small only) {
    padding: 3rem 0 1rem;

    h2 {
      font-size: 2rem;
    }

    .subline {
      padding-bottom: 1.5rem;
    }

    p {
      padding-top: 0;
    }

    svg {
      padding-bottom: 5px;
    }

    .step {
      padding: 0 1rem 1.2rem;
    }
  }
}
