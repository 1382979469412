.footer {
  padding: 0 0 80px;
  text-align: center;

  .footer-inner {
    padding: 25px 15px 0;
    border-top: 1px solid $color-line;
    font-size: 12px;
    line-height: 1.4;

    .footer-logo {
      color: $color-primary;
      font-size: 24px;
      line-height: 2;
      font-weight: 600;
      height: 18px;
      width: 113px;
      background-size: 113px 18px;
      background-image: url('../images/lp17-skg_logo_retina.png');
      background-repeat: no-repeat;
      text-indent: -99999px;
      display: inline-block;
      margin-top: 10px;
    }

    a {
      color: $color-primary;
      font-weight: 600;

      &:hover {
        color: $color-secondary;
        text-decoration: none;
      }
    }
  }

  @include breakpoint(small down) {
    padding-bottom: 45px;
  }

  //*********************************
  //   ***** DARK FOOTER *****
  //*********************************

  &.dark {
    background: $color-darkblue;
    font-size: rem-calc(26px);
    text-align: left;

    .footer-logo {
      padding: 5rem 0;
      opacity: 0.4;
    }

    p {
      color: $color-white;
      line-height: 1.3;
    }

    a.phone-btn {
      margin-top: 1rem;
      color: $color-white;
      background: $color-primary;
      padding: 1rem 2rem .8rem 5rem;
      border-radius: 7px;
      display: inline-block;
      position: relative;

      &:before {
        content: ' ';
        background-image: url('/images/icon-call-button.svg');
        width: 32px;
        height: 32px;
        display: block;
        position: absolute;
        margin-top: 0;
        top: 50%;
        left: 2rem;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        background-repeat: no-repeat;
      }

      &:hover {
        color: $color-primary;
        background: $color-white;

        &:before {
          background-image: url('/images/icon-call-button-blue.svg');
        }

      }
    }

    .footer-menu {

      li {
        list-style-type: none;

        a {
          color: $color-white;
        }

        &.current {
          a {
            //color: $color-primary;
            font-weight: bold;
            //color: #668895;
          }
        }

        &:hover {
          a {
            color: $color-primary;
          }
        }
      }
    }

    .footer-imprint {

      li {

        a {
          color: #668895;
        }

        &:hover {
          a {
            color: $color-primary;
          }
        }
      }
    }

    @include breakpoint(large down) {

      font-size: rem-calc(24px);
      padding: 0 0 3rem;

      .footer-logo {
        padding: 5rem 0 4rem;
      }

      a.phone-btn {
        padding: 1rem 1.5rem .8rem 4.5rem;
        font-size: 20px;

        &:before {
          left: 1.5rem;
        }
      }
    }

    @include breakpoint(medium down) {

      padding: 0 2rem 2rem;

      .footer-logo {
        padding: 4rem 0 3rem;
      }

      a.phone-btn {
        margin-top: 0;
        margin-bottom: 3rem;
      }

      .footer-menu {
        margin: 0;
      }
    }

    @include breakpoint(small only) {

      padding: 0 1rem 2rem;

      .footer-logo {
        padding: 3.5rem 0 2rem;
      }

      .footer-imprint {
        margin-left: 0;

        font-size: 1rem;
      }
    }

  }
}

.footer-bottom {
  background: $color-primary;
  padding: 2rem 0 1rem;

  p {
    font-size: rem-calc(20px);
    color: $color-white;
    line-height: 1.3;
    float: left;

    &.right {
      float: right;
    }
  }


  @include breakpoint(large down) {

    p {
      font-size: 1rem;
    }
  }

  @include breakpoint(medium down) {

    padding: 2rem 2rem 1rem;
  }

  @include breakpoint(small only) {

    padding: 2rem 1rem 1rem;
  }

}
