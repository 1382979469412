
.table {
  font-size: 18px;

  thead {
    th {
      background: #fff;
      font-weight: 300;
      color: $color-primary;
      border-bottom: 1px solid $color-grey;
      padding: 10px 12px;
      border-top: 1px solid $color-grey-light !important;

      &:first-child {
        border-left: 1px solid $color-grey-light;
      }

      &:last-child {
        border-right: 1px solid $color-grey-light;
      }

      &.highlight {
        background-color: $color-primary;
        color: #fff;
        border-bottom: 10px solid $color-primary;
        margin-top: -10px;
        padding-top: 20px;
        padding-bottom: 0;
        display: block;
        position: relative;
        z-index: 1;
        border-top: 0 !important;
        box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
      }
    }
  }

  tbody {
    tr {
      td {
        background-color: #fbfbfc;
        border: 0;
        font-weight: 300;
        color: $color-text;
        padding: 4px 12px;

        &.highlight-head {
          font-weight: $font-weight-semibold;
          background-color: $color-primary !important;
          color: #fff;
          border-bottom: 10px solid $color-primary !important;
          margin-top: -10px;
          padding-top: 20px !important;
          padding-bottom: 0 !important;
          position: relative;
          z-index: 1;
          border-top: 0 !important;
          box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
        }

        &.highlight {
          background-color: $color-primary-light !important;
          color: $color-primary;
          font-weight: 600;
          padding: 10px 12px;
          border-bottom: 4px solid $color-primary-light;
        }

        &:first-child {
          border-left: 1px solid $color-grey-light;
        }

        &:last-child {
          border-right: 1px solid $color-grey-light;
        }

        &.left-head {
          //background: transparent !important;
          border: 0 !important;
          border-right: 1px solid $color-grey-light !important;
          font-weight: $font-weight-semibold;
          padding: 10px !important;
          vertical-align: top;
          white-space: nowrap;
        }
      }

      &:first-child {
        td {
          &.highlight {
            margin-top: 0;
          }
        }
      }

      &:last-child {
        td {
          &.highlight {
            margin-bottom: -10px;
          }
        }
      }
    }

    &:first-child {
      tr:first-child {
        td {
          border-top: 1px solid $color-grey-light;
        }
      }
    }
  }

  &.table-striped {
    border-spacing: 0;

    tbody {
      tr {
        td {
          padding: 8px 12px;

          &.highlight {
            margin-top: 0;
          }
        }

        &:nth-child(odd) {
          td {
            background-color: #fff;
          }
        }

        &:nth-child(even) {
          td {
            background-color: #fbfbfc;
          }
        }

      }
    }
  }

  &.table-mobile {
    max-width: 100%;
    border-collapse: collapse;

    thead {
      tr {

        th {
          text-align: center;
          max-width: 200px;
          font-size: 16px;
        }

        &.th-small {
          th {
            font-size: .8rem;
            line-height: 1.2;
            padding-left: 3px;
            padding-right: 3px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          //border-bottom: 1px solid $color-grey-light;
          padding: 12px 6px;
          font-size: .8rem;
          line-height: 1.2;
          text-align: center;
        }

        &.highlight {
          td {
            background-color: $color-primary;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }

  }

  &.table-mobile-left {
    tbody tr td {
      text-align: left !important;
      vertical-align: top;
      padding: 10px !important;

      &.highlight-head {
        padding-top: 20px !important;
      }
    }
  }
}

#jumbotron,
#mobile-calculator,
#calculator {
  .calc-table {
    p {
      @include scale-font(16, 18);
      font-weight: 400;
      margin-bottom: 0;
    }

    thead,
    tbody,
    tfoot,
    tr {
      background: none;
    }

    tr.monatsrate-box {
      td {
        padding-top: 20px;

        &:last-child {
          min-width: 150px;
        }
      }
    }

    td {
      padding: 0;
      border-bottom: 0;
      background: none;
      vertical-align: bottom;

      &:last-child {
        text-align: right;

        p {
          font-weight: $font-weight-semibold;
        }
      }
    }

    @include breakpoint(large down) {
      p {
        font-size: 1rem;
      }
    }
    @include breakpoint(small down) {
      p {
        font-size: .9rem;
      }
    }

    &:first-child {
      margin-bottom: 0;
      padding-bottom: 0;
      margin-top: 2.5rem;
    }

    &:last-child {
      padding-top: 0;
      margin: 0;
    }
  }
}

#mobile-calculator {
  .calc-table {
    &:first-child {
      padding: 13px 10px 10px;
    }
  }
}
