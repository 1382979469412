
//Hind Light
@font-face {
  font-family: 'Hind-web';
  src: url('../fonts/hind/hind-light-webfont.eot');
  src: url('../fonts/hind/hind-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/hind/hind-light-webfont.woff2') format('woff2'),
  url('../fonts/hind/hind-light-webfont.woff') format('woff'),
  url('../fonts/hind/hind-light-webfont.ttf') format('truetype'),
  url('../fonts/hind/hind-light-webfont.svg#hindlight') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

//Hind Regular
@font-face {
  font-family: 'Hind-web';
  src: url('../fonts/hind/hind-regular-webfont.eot');
  src: url('../fonts/hind/hind-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/hind/hind-regular-webfont.woff2') format('woff2'),
  url('../fonts/hind/hind-regular-webfont.woff') format('woff'),
  url('../fonts/hind/hind-regular-webfont.ttf') format('truetype'),
  url('../fonts/hind/hind-regular-webfont.svg#hind_regularregular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

//Hind Medium
@font-face {
  font-family: 'Hind-web';
  src: url('../fonts/hind/hind-medium-webfont.eot');
  src: url('../fonts/hind/hind-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/hind/hind-medium-webfont.woff2') format('woff2'),
  url('../fonts/hind/hind-medium-webfont.woff') format('woff'),
  url('../fonts/hind/hind-medium-webfont.ttf') format('truetype'),
  url('../fonts/hind/hind-medium-webfont.svg#hindmedium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

//Hind Semibold
@font-face {
  font-family: 'Hind-web';
  src: url('../fonts/hind/hind-semibold-webfont.eot');
  src: url('../fonts/hind/hind-semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/hind/hind-semibold-webfont.woff2') format('woff2'),
  url('../fonts/hind/hind-semibold-webfont.woff') format('woff'),
  url('../fonts/hind/hind-semibold-webfont.ttf') format('truetype'),
  url('../fonts/hind/hind-semibold-webfont.svg#hindsemibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

//Hind Bold
@font-face {
  font-family: 'Hind-web';
  src: url('../fonts/hind/hind-bold-webfont.eot');
  src: url('../fonts/hind/hind-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/hind/hind-bold-webfont.woff2') format('woff2'),
  url('../fonts/hind/hind-bold-webfont.woff') format('woff'),
  url('../fonts/hind/hind-bold-webfont.ttf') format('truetype'),
  url('../fonts/hind/hind-bold-webfont.svg#hindbold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}



//Linearicons
@font-face {
  font-family: 'Linearicons-Free';
  src: url('../fonts/linearicons/Linearicons-Free.eot?w118d');
  src: url('../fonts/linearicons/Linearicons-Free.eot?#iefixw118d') format('embedded-opentype'),
  url('../fonts/linearicons/Linearicons-Free.woff2?w118d') format('woff2'),
  url('../fonts/linearicons/Linearicons-Free.woff?w118d') format('woff'),
  url('../fonts/linearicons/Linearicons-Free.ttf?w118d') format('truetype'),
  url('../fonts/linearicons/Linearicons-Free.svg?w118d#Linearicons-Free') format('svg');
  font-weight: normal;
  font-style: normal;
}

//Handwritten
@font-face {
  font-family: 'handwritten';
  src: url('../fonts/skg-handwriting-webfont/skg-handwriting-webfont.eot');
  src: url('../fonts/skg-handwriting-webfont/skg-handwriting-webfont.eot') format('embedded-opentype'),
  url('../fonts/skg-handwriting-webfont/skg-handwriting-webfont.woff') format('woff2'),
  url('../fonts/skg-handwriting-webfont/skg-handwriting-webfont.woff') format('woff'),
  url('../fonts/skg-handwriting-webfont/skg-handwriting-webfont.ttf') format('truetype'),
  url('../fonts/skg-handwriting-webfont/skg-handwriting-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
