#off-canvas {
  z-index: 1010;
  background: $color-white;
  //height: auto;
}

//canvas-off option
body {
  .top-bar .menu .menu-item {
    opacity: 1;
  }

  &.canvas-off-open {
    overflow: hidden;

    .top-bar .menu .menu-item {
      opacity: 0;
    }
  }
}

.off-canvas {

  .row {
    padding: 0 20px;
  }

  .box {
    padding: 0 10px;

    a.btn.cta {
      padding: 1rem 3rem .9rem;
    }
  }

  ul {
    list-style-type: none;
    padding: 1.5rem 10px 0 0;
    margin-left: 10px;

    li {
      padding-left: 0;
      margin-left: 0;
      font-size: 1rem;
      color: $color-primary;
      //text-transform: uppercase;
      padding: 14px 0 10px;
      border-bottom: 1px solid $color-line;
      position: relative;

      a {
        //text-transform: uppercase;
        display: block;
      }

      &.current {
        > a,
        > span {
          font-weight: bold;
        }
      }

      &:after {
        content: '';
        font-family: 'Linearicons-Free';
        display: block;
        position: absolute;
        top: 12px;
        right: 15px;
        width: 6px;
        height: 11px;
        background-repeat: no-repeat;
        background-size: 6px 11px;
      }
    }
  }

  .has-submenu {
    &:after {
      display: none;
    }
  }

  .menu.products {
    padding: 0;
    padding-bottom: 5px;

    li {
      display: block;
      border: 0;
      margin-right: 0;
      padding-bottom: 5px;

      &.current {
        a,
        span {
          font-weight: bold;
        }
      }

      &:after {
        top: 11px;
      }

      a {
        text-transform: none;
      }
    }
  }

  .service-box {
    position: absolute;
    bottom: 0;
    width: 100%;

    @media screen and (max-height: 650px) {
      position: static;
    }

    .box {
      background: $color-grey;
      margin-top: 20px;
      padding: 15px 30px;
    }
  }

  .canvas-off-phone {
    position: relative;
    margin-left: 40px;
    font-size: 1.2rem;
    color: $color-primary;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: -40px;
      width: 25px;
      height: 20px;
      background-image: url('../images/lp17-phone.svg');
      background-repeat: no-repeat;
      background-size: 25px 20px;
    }

    span {
      margin-top: -3px;
      color: $color-grey-dark;
      font-size: 1rem;
      display: block;
    }
  }

  .canvas-off-mail {
    font-size: 1.2rem;
    position: relative;
    margin-left: 40px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: -40px;
      width: 25px;
      height: 20px;
      background-image: url('../images/lp17-mail.svg');
      background-repeat: no-repeat;
      background-size: 25px 20px;
    }
  }

  .close-button {
    color: $color-primary;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 10px;
    top: 5px;
    padding: 0 0 2px;
    font-weight: bold;
    cursor: pointer;
    outline: none;

    &:focus,
    &:active {
      color: $color-primary;
    }
  }

}

.btn-login {
  font-size: 1.2rem !important;
  text-transform: none !important;
  font-weight: 400 !important;
  background: $color-grey-medium;
  border: 0 !important;
  padding: 15px 50px 12px !important;
  width: 100%;
  display: block;

  &:before {
    display: none !important;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    //right: 30px;
    left: 30px;
    width: 10px;
    height: 14px;
    background-image: url('../images/lp17-icon-lock.png');
    background-repeat: no-repeat;
    background-size: 10px 28px;
    background-position-y: -14px;
  }
}

.js-off-canvas-overlay {
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
