ul.list-checked {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 1rem;
    color: $color-text;
    padding-left: 1.75em;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      background: url('../images/check.svg') no-repeat;
      width: 1.5em;
      height: 1em;
    }

  }
}
