body {
  padding-top: 85px;
  background: $white;

  @include breakpoint(medium down) {
    padding-top: 55px;
  }

  @include breakpoint(small down) {
    padding-top: 52px;
  }

}

.column {

  &[class*='-centered'] {
    margin-right: auto;
    margin-left: auto;
  }

  &.multiproduct {
    padding-top: 85px;
  }
}

a {
  outline: none;
}

img {
  &.image-top {
    object-position: top;
  }

  &.image-bottom {
    object-position: bottom;
  }

  &.image-center {
    object-position: center;
  }

}

.caption {
  display: inline;
  background: $color-secondary;
  color: $color-darkblue;
  padding: 0.25em;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  font-weight: 300;
  font-size: 26px;
  line-height: 1.45;
  top: -0.55em;
  position: relative;
  hyphens: auto;

  .date {
    font-weight: 700;
  }
}
