.badge {
  background: $color-secondary;
  color: $color-text;
  padding: 10px;
  display: inline-block;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: transform 0.4s cubic-bezier(1, 0.24, 0.19, 1.4), opacity 0.2s ease-out;
  transition-delay: 0.5s, 0s;
  transform-origin: bottom;
  transform: scaleY(0);

  &.is-large {
    left: 0 !important;
    right: 0 !important;
    display: block;
  }

  &.is-hidden-first {
    transition: transform 0.4s cubic-bezier(1, 0.24, 0.19, 1.8), opacity 0.2s ease-out;
    z-index: 8;
    top: 10px !important;
    opacity: 0;
    transition-delay: 0s;
    position: absolute !important;
  }

  .fa-loaded & {
    transform: none;
  }
}

.jumbo-calc-container,
.product-tile .box,
#mobile-calculator {
  position: relative;

  @include breakpoint(small) {
    .badge {
      position: absolute;
      top: -20px;
      left: 0;
      z-index: 100;
      font-size: 14px;
    }
  }

  @include breakpoint(medium) {
    .badge {
      position: absolute;
      left: -1px;
      top: -30px;
      font-size: $global-font-size;
      z-index: 100;
    }
  }

  @include breakpoint(large) {
    .badge {
      position: absolute;
      left: -30px;
      top: -30px;
      z-index: 100;
    }
  }
}

#mobile-calculator {
  @include breakpoint(small only) {
    .badge {
      position: static;
    }
  }
}
